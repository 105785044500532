import {PtoListState} from "../../workspace/pto/list/data/state";
import {KcListState} from "../../workspace/kc/list/data/state";
import {User} from "../client/auth/client";
import {SimpleNotification} from "../notification/type";
import {PtoEditorState} from "../../workspace/pto/editor/data/editor_reducer";


interface AuthState {
    token?: string
    expiredTime?: number
    userInfo?: User

    isLogged: boolean
    isWaiting: boolean
    errorMessage?: string
}

function CheckExpiredTime(expiredTime: number | undefined): boolean {
    return !!expiredTime && expiredTime - Date.now() / 1000 < 0
}

interface AppState {
    // system
    auth: AuthState
    notification: SimpleNotification[]
    // app
    pto: PtoListState
    ptoEditor: PtoEditorState
    kc: KcListState
}

export type {AppState, AuthState}
export {CheckExpiredTime}