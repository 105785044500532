import React, {useEffect, useReducer} from "react";

import {Stack, Tab, Tabs} from "@mui/material";

import {TabPanel} from "../../../../../qdep/components/util/tab-panel/TabPanel";
import {Invoices, Items} from "../../../../../app/client/app/entity/pto";
import {PtoObjectsWorkspaceBlock} from "../pto_item/list/PtoObjectsWorkspaceBlock";
import {Spacer} from "../../../../../qdep/components/util/spacer/Spacer";
import {GroupAddObjectButton} from "../pto_item/list/AddObjectButton";
import {PtoEditorItemsReducer} from "../../data/items_reducer";
import {PtoInvoiceList} from "./invoice/PtoInvoiceList";
import {PtoInvoiceListReducer} from "../../data/invoice_reducer";


interface EditorMainTabsProps {
    items: Items
    updateObjects: (items: Items) => void

    invoices: Invoices
    updateInvoices: (invoices: Invoices) => void
}

const EditorMainTabs = (props: EditorMainTabsProps) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const [itemsTabState, itemsTabDispatch] = useReducer(PtoEditorItemsReducer, props.items);
    useEffect(() => {
        props.updateObjects(itemsTabState)
        // todo fix
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsTabState, props.updateObjects])

    const [invoiceTabState, invoiceTabDispatch] = useReducer(PtoInvoiceListReducer, props.invoices);
    useEffect(() => {
        props.updateInvoices(invoiceTabState)
        // todo fix
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceTabState, props.updateInvoices])


    return <>
        <Stack direction={"row"} alignItems={"center"}>
            <Tabs value={tabIndex} onChange={handleChange} indicatorColor={"secondary"} textColor={"secondary"}>
                <Tab sx={{fontSize: "16px", fontWeight: "400"}} label={"Запланированные работы"}/>
                <Tab sx={{fontSize: "16px", fontWeight: "400"}} label={"Счета"}/>
            </Tabs>
            <Spacer/>
            { tabIndex === 0 &&
                <GroupAddObjectButton
                    disableCreateDefaultObject={props.items.hasDefaultObject}
                    dispatch={itemsTabDispatch}
                />
            }
        </Stack>
        <div style={{padding: "15px 0"}}>
            <TabPanel index={0} value={tabIndex}>
                <PtoObjectsWorkspaceBlock
                    objects={itemsTabState.objects}
                    dispatch={itemsTabDispatch}
                />
            </TabPanel>
            <TabPanel index={1} value={tabIndex}>
                <PtoInvoiceList
                    invoices={invoiceTabState}
                    dispatch={invoiceTabDispatch}
                />
            </TabPanel>
        </div>
    </>
}

export {EditorMainTabs}