enum IssueType {
    KC2_WRONG_ITEM_TOTAL_MATERIAL_PRICE = "kc2_item_wrong_material_total_price",
    KC2_WRONG_ITEM_TOTAL_WORK_PRICE     = "kc2_item_wrong_work_total_price",

    KC2_ANOTHER_ITEM_MATERIAL_PRICE = "kc2_item_another_material_price",
    KC2_ANOTHER_ITEM_WORK_PRICE     = "kc2_item_another_work_price",
    KC2_ANOTHER_ITEM_QUANTITY       = "kc2_item_another_quantity",
    KC2_ANOTHER_ITEM_NAME           = "kc2_another_item_name",
    KC2_ANOTHER_ITEM_UNIT           = "kc2_another_item_unit",
    KC2_UNKNOWN_ITEM                = "kc2_unknown_item",

    KC2_WRONG_TOTAL_PRICE          = "kc2_wrong_total_price",
    KC2_WRONG_TOTAL_MATERIAL_PRICE = "kc2_wrong_material_total_price",
    KC2_WRONG_TOTAL_WORK_PRICE     = "kc2_wrong_work_total_price",
    KC2_NOT_ASSIGNED_TO_PTO        = "kc2_not_assigned_to_pto",
}

interface DocumentIssue {
    id: number
    document_type: string
    document_id: string
    issue_type: string
    data: Record<string, any>
}

export {IssueType}
export type {DocumentIssue}