import React from "react";

import './Workspace.css'


interface WorkspaceProps {
    children:  React.ReactNode

    className?: string
}

const Workspace = (props: WorkspaceProps) => {
    let workspaceClasses = "workspace"
    if (props.className) {
        workspaceClasses += " " + props.className
    }

    return <div className={"workspace-container"}>
        <div className={workspaceClasses}>
            {props.children}
        </div>
    </div>;
}

export { Workspace }