import React, {useEffect, useState} from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField} from "@mui/material";

import {Invoice} from "../../../../../../app/client/app/entity/pto";
import {MoneyFormatController} from "../../../../../../app/ui/formatter";
import {InvoiceStatusLabel} from "./InvoiceStatusLabel";
import Decimal from "decimal.js-light";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment/moment";

const defaultInvoice = {
    name: "",
    date: moment(),
    value: new Decimal("0"),
    status: "WAIT",
    comment: "",
};

interface InvoiceEditorDialogProps {
    isOpen: boolean
    close: () => void

    invoice: Invoice
    apply: (invoice: Invoice) => void
}

const InvoiceEditorDialog = (props: InvoiceEditorDialogProps) => {
    const [entity, setEntity] = useState(props.invoice);
    useEffect(() => {
        setEntity(props.invoice)
    }, [props.invoice])

    return <Dialog open={props.isOpen}>
        <DialogTitle>Создать счет</DialogTitle>
        <DialogContent>
            <div className={"filed-container"}>
                <label>Наименование</label>
                <TextField
                    className={"field-control"} size={"small"}
                    value={entity.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEntity(prev => ({...prev, name: event.target.value}))
                    }}
                />
            </div>
            <div className={"filed-container"}>
                <label>Дата</label>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={entity.date}
                        onChange={(date: moment.Moment | null) => {
                            setEntity(prev => ({...prev, date: date || moment()}))
                        }}

                        renderInput={(params) =>
                            <TextField className={"field-control"} size={"small"} {...params} />
                        }
                    />
                </LocalizationProvider>
            </div>
            <div className={"filed-container"}>
                <label>Сумма</label>
                <TextField
                    className={"field-control"} size={"small"}
                    value={entity.value.toString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEntity(prev => ({...prev, value: new Decimal(event.target.value || "0")}))
                    }}
                    InputProps={{inputComponent: MoneyFormatController as any}}
                />
            </div>
            <div className={"filed-container"}>
                <label>Статус</label>
                <Select
                    className={"field-control"}
                    size={"small"}
                    onChange={(event) => setEntity(prev => ({...prev, status: event.target.value}))}
                    value={entity.status}
                >
                    <MenuItem value={"WAIT"}><InvoiceStatusLabel value={"WAIT"}/></MenuItem>
                    <MenuItem value={"PAID"}><InvoiceStatusLabel value={"PAID"}/></MenuItem>
                </Select>
            </div>
            <div className={"filed-container not-align"}>
                <label style={{lineHeight: "40px"}}>Примечание</label>
                <TextField
                    className={"field-control"} size={"small"}
                    multiline
                    rows={3}
                    value={entity.comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEntity(prev => ({...prev, comment: event.target.value}))
                    }}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Отменить</Button>
            <Button variant="contained" color="secondary"
                    onClick={() => {
                        props.apply(entity)
                        props.close()
                        setEntity(defaultInvoice)
                    }}
            >
                Применить
            </Button>
        </DialogActions>
    </Dialog>
}

export {InvoiceEditorDialog, defaultInvoice}
