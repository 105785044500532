import React from "react";

import {Divider, Stack} from "@mui/material";

import {InvoiceOverview, KcOverview, OverviewBlockData} from "../../../../app/client/app/entity/pto_report";
import {MoneyFormat} from "../../../../app/ui/formatter";
import {InvoiceStatusLabel} from "../../editor/ui/tabs/invoice/InvoiceStatusLabel";

import './PtoReportOverview.css'
import styles from './PtoReportOverview.module.css'
import {Label} from "../../../../qdep/components/util/label/Label";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import WarningIcon from "@mui/icons-material/Warning";

interface OverviewBlockProps {
    data: OverviewBlockData
}

const OverviewBlock = (props: OverviewBlockProps) => {
    return <div className={"overview_block"}>
        <div className={"header"}>
            { props.data.title }
        </div>
        <div className={"value"}>
            { props.data.value}
        </div>
    </div>
}

interface PtoReportOverviewProps {
    blocks: OverviewBlockData[]
    invoiceOverview: InvoiceOverview
    kcOverview: KcOverview
}

const PtoReportOverview = (props: PtoReportOverviewProps) => {
    return <div className={"workspace-block"}>
        <div className={"header"}>
            Краткий обзор
        </div>
        <div className={"body"}>
            <div className={"overview_block_container"}>
                { props.blocks.map(block => <OverviewBlock data={block}/>) }
            </div>
            <Divider/>
            <div className={styles.overview_lists_wrapper}>
                <div className={styles.overview_list_container}>
                    <div className={styles.header}>
                        Счета ({props.invoiceOverview.invoices.length})
                    </div>
                    { (props.invoiceOverview.invoices && props.invoiceOverview.invoices.length > 0) &&
                        <div className={"report-overview-invoices-grid"}>
                            {props.invoiceOverview.invoices.map((invoice, index) =>
                                <>
                                    <span className={"desc"}>{index + 1}</span>
                                    <span className={"name"}>
                                        {invoice.name}{invoice.date !== undefined && " от " + invoice.date.format('DD.MM.YYYY')}
                                    </span>
                                    <span className={"value"}><MoneyFormat value={invoice.value.toString()}/></span>
                                    <div><InvoiceStatusLabel value={invoice.status}/></div>
                                    <span className={"comment"}>{invoice.comment}</span>
                                </>
                            )}
                            <span/>
                            <span/>
                            <span className={"value"}><MoneyFormat value={props.invoiceOverview.total}/></span>
                            <span className={"total_issue"}>
                                { props.invoiceOverview.moreThanContractAmount &&
                                    <Stack direction={"row"} alignItems={"center"} spacing={1} style={{color: "rgb(229, 44, 91)"}}>
                                        <WarningRoundedIcon color={"error"}/>
                                        <span>Превышение суммы договора</span>
                                    </Stack>
                                }
                            </span>
                        </div>
                    }
                    { !(props.invoiceOverview.invoices && props.invoiceOverview.invoices.length > 0) &&
                        <span>Нет прикрепленных счетов</span>
                    }
                </div>

                <div className={styles.overview_list_container}>
                    <div className={styles.header}>
                        KC-2 ({props.kcOverview.kcs.length})
                    </div>
                    { (props.kcOverview.kcs && props.kcOverview.kcs.length > 0) &&
                        <div className={styles.report_overview_kc_grid}>
                            {props.kcOverview.kcs.map((kc, index) =>
                                <>
                                    <span className={styles.report_overview_kc_grid_desc}>{index + 1}</span>
                                    <span className={styles.report_overview_kc_grid_name}>{kc.name}</span>
                                    <span className={styles.report_overview_kc_grid_value}><MoneyFormat value={kc.value.toString()}/></span>
                                    <span>
                                        { kc.signFlag
                                            ? <Label variant={"success"} text="Подписано"/>
                                            : <Label variant={"error"} text="Не подписано"/>
                                        }
                                    </span>
                                    <span>
                                        { kc.issues > 0 &&
                                            <Stack direction={"row"} alignItems={"center"} spacing={1} style={{color: "rgb(229, 44, 91)"}}>
                                                <WarningRoundedIcon color={"error"}/>
                                                <span>{ kc.issues } ошибок</span>
                                            </Stack>
                                        }
                                    </span>
                                </>
                            )}
                            <span/>
                            <span/>
                            <span className={styles.report_overview_kc_grid_value}><MoneyFormat value={props.kcOverview.total}/></span>
                            <span/>
                        </div>
                    }
                    { !(props.kcOverview.kcs && props.kcOverview.kcs.length > 0) &&
                        <span>Нет прикрепленных докуметов</span>
                    }
                </div>
            </div>
        </div>
    </div>
}

export {PtoReportOverview}
