import React from "react";
import {NavLink} from "react-router-dom";

import './Menu.css'

interface MenuItemProps {
    title: string
    path: string
}

class MenuItem extends React.Component<MenuItemProps, {}> {
    render() {
        return <li className={"menu-leaf-item"}>
            <NavLink to={this.props.path} activeClassName={"selected-item"}>{this.props.title}</NavLink>
        </li>;
    }
}

class Menu extends React.Component<{}, {}> {
    render(): React.ReactNode {
        return <div className={"main-menu-container"}>
            { this.props.children }
        </div>;
    }
}

class MenuNavigationContainer extends React.Component<{}, {}> {
    render() {
        return <nav>
            <ul>
                { this.props.children }
            </ul>
        </nav>;
    }
}

export { Menu, MenuItem, MenuNavigationContainer }