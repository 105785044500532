import {AnyAction} from "redux";

import {Invoice, Invoices} from "../../../../app/client/app/entity/pto";


enum PtoInvoiceListActions {
    ADD    = "INVOICE.ADD",
    DELETE = "INVOICE.DELETE",
    UPDATE = "INVOICE.UPDATE",
}

function addInvoice(invoice: Invoice): AnyAction {
    return {
        type: PtoInvoiceListActions.ADD,
        entity: invoice,
    }
}

function deleteInvoice(itemIndex: number): AnyAction {
    return {
        type: PtoInvoiceListActions.DELETE,
        index: itemIndex
    }
}

function updateInvoice(recordIndex: number | undefined, invoice: Invoice): AnyAction {
    return {
        type: PtoInvoiceListActions.UPDATE,
        recordIndex: recordIndex,
        entity: invoice,
    }
}

function PtoInvoiceListReducer(state: Invoices, action: AnyAction): Invoices {
    switch (action.type) {
        case PtoInvoiceListActions.ADD:
            return addInvoiceReducer(state, action)
        case PtoInvoiceListActions.UPDATE:
            return updateInvoiceReducer(state, action)
        case PtoInvoiceListActions.DELETE:
            return deleteInvoiceReducer(state, action)
    }
    return state
}

function addInvoiceReducer(state: Invoices, action: AnyAction): Invoices {
    if (action.entity) {
        return {
            seq: state.seq + 1,
            items: [...state.items, {rowIndex: state.seq, data: action.entity as Invoice}],
        }
    }
    return state;
}

function updateInvoiceReducer(state: Invoices, action: AnyAction): Invoices {
    if (action.entity && action.recordIndex && action.recordIndex >= 0) {
        const itemIndex = state.items.findIndex((item) => item.rowIndex === action.recordIndex);
        if (itemIndex > -1) {
            let items = [...state.items]
            items[itemIndex] = {
                ...items[itemIndex],
                data: action.entity as Invoice
            }
            return {
                ...state,
                items: items
            }
        }
    }
    return state;
}

function deleteInvoiceReducer(state: Invoices, action: AnyAction): Invoices {
    if (action.index !== undefined) {
        if (action.index > -1 && action.index < state.items.length) {
            let items = [...state.items]
            items.splice(action.index, 1);
            console.log(items)
            return {
                ...state,
                items: items
            }
        }
    }
    return state;
}

export {
    PtoInvoiceListReducer,
    addInvoice, deleteInvoice, updateInvoice,
}