import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import {AppState} from "../../../../../app/state/state";
import {syncRequest} from "../../data/action";


const syncOpDisabledSelector = (state: AppState) => state.kc.syncOpDisabled || false

const SyncButton = () => {
    const dispatch = useDispatch();
    const syncOpDisabled = useSelector(syncOpDisabledSelector);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    return <div className="entity-table-control-panel-btn-group">
        <Button
            variant="outlined"
            sx={{padding: "0 5px 0 12px"}}
            ref={anchorRef}
            startIcon={<CloudSyncIcon/>}
            disabled={syncOpDisabled}
            onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
            Синхронизация <ArrowDropDownIcon/>
        </Button>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={"bottom-end"}
            style={{zIndex: 10}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <MenuList id="split-button-menu">
                                <MenuItem
                                    key="sync-3h"
                                    disabled={true}
                                    onClick={() => {
                                        dispatch(syncRequest("3hour"))
                                        setOpen(false)
                                    }}
                                >
                                    Синхронизация (3 часа)
                                </MenuItem>
                                <MenuItem
                                    key="sync-1d"
                                    onClick={() => {
                                        dispatch(syncRequest("1day"))
                                        setOpen(false)
                                    }}
                                >
                                    Синхронизация (1 день)
                                </MenuItem>
                                <MenuItem
                                    key="sync-7d"
                                    onClick={() => {
                                        dispatch(syncRequest("7day"))
                                        setOpen(false)
                                    }}
                                >
                                    Синхронизация (7 дней)
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>
}

export {SyncButton}
