import {TextField} from "@mui/material";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {AuthRequestData} from "../../../app/client/auth/client";
import {useDispatch} from "react-redux";
import {loginRequest} from "./data";


interface LoginWorkspaceBlockProps {
    isWaiting: boolean
}

const LoginWorkspaceBlock = (props : LoginWorkspaceBlockProps) => {
    const [state, setState] = useState({} as AuthRequestData);
    const dispatch = useDispatch();

    return <div className={"workspace-block login-page-workspace-block"}>
        <div className={"header"}>
            Вход в систему
        </div>
        <div className={"body login-page-form-container"}>
            <div className={"filed-container small fill"}>
                <label>Логин</label>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    disabled={props.isWaiting}
                    value={state.username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, username: event.target.value}))
                    }}
                />
            </div>
            <div className={"filed-container small fill"}>
                <label>Пароль</label>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    type={"password"}
                    disabled={props.isWaiting}
                    value={state.password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, password: event.target.value}))
                    }}
                />
            </div>

            <div className={"login-page-action-container"}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={props.isWaiting}
                    onClick={() => {
                        dispatch(loginRequest(state.username, state.password))
                    }}
                >
                    Войти
                </LoadingButton>
            </div>
        </div>
    </div>
}

export {LoginWorkspaceBlock}