import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {Stack, Tab, Tabs} from "@mui/material";

import {Kc} from "../../../../../app/client/app/entity/kc";
import {getEntityRequest} from "../../data/action";
import {KcDocumentPreviewItems} from "./tabs/KcDocumentPreviewItems";
import {KcDocumentPreviewLogs} from "./tabs/KcDocumentPreviewLogs";
import {KcOverview} from "./tabs/KcOverview";
import {PreviewControlPane} from "./control-pane/PreviewControlPane";

import styles from './KcDocumentView.module.css'
import './DeprecatedKcDocumentView.css'


interface KcDocumentPreviewTabPanelProps {
    index: number;
    value: number;

    children: React.ReactElement
}

const KcDocumentPreviewTabPanel = (props: KcDocumentPreviewTabPanelProps) => {
    if (props.index !== props.value) {
        return <></>
    }
    return props.children;
}

interface KcDocumentPreviewProps {
    entity: Kc,
}

const KcDocumentView = (props: KcDocumentPreviewProps) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const dispatch = useDispatch();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        if (props.entity.items.isLoaded || props.entity.issues.isLoaded) {
            dispatch(getEntityRequest(props.entity.id))
        }
    }, [props.entity.items.isLoaded, props.entity.issues.isLoaded, props.entity.id, dispatch])

    return (
        <Stack direction={"row"}>
            <div className={styles.kc_document_preview_content_container}>
                <Tabs value={tabIndex} onChange={handleChange} indicatorColor={"secondary"} textColor={"secondary"}>
                    <Tab label={"Краткий обзор"}/>
                    <Tab label={"Работы"}/>
                    <Tab label={"Ошибки"}/>
                </Tabs>
                <KcDocumentPreviewTabPanel index={0} value={tabIndex}>
                    <KcOverview entity={props.entity}/>
                </KcDocumentPreviewTabPanel>
                <KcDocumentPreviewTabPanel index={1} value={tabIndex}>
                    <KcDocumentPreviewItems items={props.entity.items.data}/>
                </KcDocumentPreviewTabPanel>
                <KcDocumentPreviewTabPanel index={2} value={tabIndex}>
                    <KcDocumentPreviewLogs items={props.entity.issues.data}/>
                </KcDocumentPreviewTabPanel>
            </div>
            <PreviewControlPane
                documentId={props.entity.id}
                documentName={props.entity.name}
                documentSignStatus={props.entity.sign_flag}
            />
        </Stack>
    );
}

export {KcDocumentView}