import React, {useCallback, useEffect} from "react";
import {AnyAction, Dispatch} from "redux";
import {connect} from "react-redux";
import {goBack} from "connected-react-router";
import moment from "moment/moment";
import Decimal from "decimal.js-light";

import {Button, CircularProgress, Divider, Stack, TextField, Typography} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";

import {MoneyFormat, MoneyFormatController} from "../../../../app/ui/formatter";
import {Invoices, NewPto, Pto, Items} from "../../../../app/client/app/entity/pto";
import {PtoEditorState} from "../data/editor_reducer";
import {AppState} from "../../../../app/state/state";
import {getEntityRequest, updateEditorState} from "../data/action";
import {EditorMainTabs} from "./tabs/EditorMainTabs";

import './PtoEditor.css'


interface PtoEditorWorkspaceBlockProps {
    updateEntity: (data: Partial<Pto>) => void
    saveEntity: (action: AnyAction) => void
    closeEditor: () => void
    reloadEntity: (entityId: number) => void

    saveAction: (entity: Pto) => AnyAction
}

const PtoEditor = (props: PtoEditorWorkspaceBlockProps & PtoEditorState) => {
    // todo fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateEntity = useCallback((data: Partial<Pto>) => {props.updateEntity(data)}, [])
    const updateObjects = useCallback((items: Items) => {
        updateEntity({items: items})
    // todo fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const updateInvoices = useCallback((invoices: Invoices) => {
        updateEntity({invoices: invoices})
        // todo fix
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const ptoTotal = Object.keys(props.entity.items.objects)
            .map((object: string) =>
                props.entity.items.objects[object].items
                    .map(item => item.data)
                    .filter(item => item.materialPrice && item.workPrice && item.quantity)
                    .map(item => item.materialPrice.add(item.workPrice).mul(item.quantity))
                    .reduce((prev, curr) => prev.add(curr), new Decimal(0))
            )
            .reduce((prev, curr) => prev.add(curr), new Decimal(0))

        updateEntity({pto_total: ptoTotal})
    }, [props.entity.items, updateEntity])

    if (props.page === "loading") {
        return <div className={"editor-loader-container"}>
            <CircularProgress color="secondary" size={64}/>
        </div>
    }

    if (props.page === "get_entity_error" || props.page === "not_found") {
        return <Stack direction={"column"} justifyContent={"center"} spacing={2} paddingTop={10}>
            <Typography variant={"h3"}>Whoops, something went wrong on our end.</Typography>
            <Stack>
                <Typography>
                    Try refreshing the page, or going back and attempting the action again.
                </Typography>
                <Typography>
                    Please contact your administrator if this problem persists.
                </Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
                <Button onClick={() => props.closeEditor()}>
                    Назад
                </Button>
                { props.entityId !== undefined &&
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {props.entityId && props.reloadEntity(props.entityId)}}
                    >
                        Обновить
                    </Button>
                }
            </Stack>
        </Stack>
    }

    return <>
        <div className={"workspace-block"}>
            <div className={"header"}>
                Базовые параметры
            </div>
            <div className={"body"}>
                <div className={"filed-container"}>
                    <label>Номер договора</label>
                    <TextField
                        className={"field-control"} size={"small"}
                        value={props.entity.contract_number}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateEntity({contract_number: event.target.value})
                        }}
                    />
                </div>
                <div className={"filed-container"}>
                    <label>Дата заключения</label>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={props.entity.contract_date}
                            onChange={(date: moment.Moment | null) => {
                                updateEntity({contract_date: date || moment()})
                            }}

                            renderInput={(params) =>
                                <TextField className={"field-control"} size={"small"} {...params} />
                            }
                        />
                    </LocalizationProvider>
                </div>

                <div className={"filed-container"}>
                    <label>Стоимость ИТОГО</label>
                    <span className={"field-control"}>
                        <MoneyFormat value={props.entity.pto_total.toString()}/>
                    </span>
                </div>
                <div className={"filed-container"}>
                    <label>Аванс</label>
                    <TextField
                        className={"field-control"} size={"small"}
                        value={props.entity.prepayment.toString()}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateEntity({prepayment: new Decimal(event.target.value || "0")})
                        }}
                        InputProps={{inputComponent: MoneyFormatController as any}}
                    />
                </div>

                <Divider sx={{margin: "16px"}}/>
                <div className={"filed-container"}>
                    <label>Заказчик</label>
                    <TextField
                        className={"field-control"} size={"small"}
                        value={props.entity.customer}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateEntity({customer: event.target.value})
                        }}
                    />
                </div>
                <div className={"filed-container"}>
                    <label>Подрядчик</label>
                    <TextField
                        className={"field-control"} size={"small"}
                        value={props.entity.contractor}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateEntity({contractor: event.target.value})
                        }}
                    />
                </div>
                <div className={"filed-container"}>
                    <label>Поселок</label>
                    <TextField
                        className={"field-control"} size={"small"}
                        value={props.entity.settlement}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateEntity({settlement: event.target.value})
                        }}
                    />
                </div>
            </div>
        </div>

        <EditorMainTabs
            items={props.entity.items}
            updateObjects={updateObjects}

            invoices={props.entity.invoices}
            updateInvoices={updateInvoices}
        />

        <div className={"editor-save-action-container"}>
            <Button variant="contained" color="secondary"
                    onClick={() => {
                        props.saveEntity(props.saveAction(props.entity))
                    }}
            >
                Сохранить
            </Button>
            <Button onClick={() => props.closeEditor()}>
                Отменить
            </Button>
        </div>
    </>;
}

const mapStateToProps = (state: AppState): PtoEditorState => {
    return {
        page: state.ptoEditor.page,
        entityId: state.ptoEditor.entityId,
        entity: state.ptoEditor.entity,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): Omit<PtoEditorWorkspaceBlockProps, "saveAction"> => {
    return {
        updateEntity: (data: Partial<Pto>) => dispatch(updateEditorState(data)),
        saveEntity: (action: AnyAction) => dispatch(action),
        closeEditor: () => {
            dispatch(goBack())
            dispatch(updateEditorState(NewPto()))
        },
        reloadEntity: (entityId: number) => dispatch(getEntityRequest(entityId)),
    }
}

const ConnectedPtoEditor = connect(mapStateToProps, mapDispatchToProps)(PtoEditor)

export {ConnectedPtoEditor as PtoEditor}
