import React, {useCallback, useEffect, useState} from "react";
import {AnyAction} from "redux";
import Decimal from "decimal.js-light"

import {Box, Button, Chip, Divider, ListItemIcon, Menu, MenuItem, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import {ColumnSpec, defaultColumnSpecKey, QTableAction, QTableStaticProps, RowFactory} from "@qdep/react-table";

import {EntityListBlock} from "../../../../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {DefaultObjectName, PtoItem} from "../../../../../../app/client/app/entity/pto";
import {PtoItemEditor} from "../editor/PtoItemEditor";
import {DecimalFormat, MoneyFormat} from "../../../../../../app/ui/formatter";
import {
    addObjectItemAction,
    deleteObjectAction,
    deleteObjectItemAction,
    updateObjectItemAction
} from "../../../data/action";
import {IndexedTableData} from "../../../../../../app/client/app/entity/util";
import {DeleteButton} from "../../../../../../qdep/components/util/delete_btn/DeleteButton";

import './PtoItemListWorkspaceBlock.css'

const PtoItemListBlock = EntityListBlock

function defaultCellFormatter(data: any): string {
    return data ? data : '-'
}

const customRowFactory: (objectName: string, dispatch: React.Dispatch<AnyAction>, openMenu: (x: number, y: number, entityIndex: number) => void) => RowFactory
    = (objectName: string, dispatch: React.Dispatch<AnyAction>, openMenu: (x: number, y: number, entityIndex: number) => void) => (rowData: any, props: QTableStaticProps & QTableAction) =>
{
    let cells: React.ReactNode[] | React.ReactNode
    const entity = rowData.data;
    if (props.selectedRow !== undefined && props.selectedRow === rowData.rowIndex) {
        cells = <PtoItemEditor
            entity={entity}
            updateItemCallback={(entity) => dispatch(updateObjectItemAction(objectName, {
                rowIndex: rowData.rowIndex,
                data: {
                    index: parseInt(entity.index, 10),
                    name: entity.name,
                    units: entity.units,
                    quantity: new Decimal(entity.quantity),
                    materialPrice: new Decimal(entity.materialPrice),
                    workPrice: new Decimal(entity.workPrice),
                },
            }))}
        />
    } else {
        cells = props.spec.map((column: ColumnSpec) => {
            let value: any
            if (column.calc) {
                value = column.calc(entity)
            } else {
                value = entity[column.name];
            }
            const cellValue = column.format ? column.format(value) : defaultCellFormatter(value);
            return <td
                key={defaultColumnSpecKey(column)}
                contextMenu={undefined}
            >
                { cellValue }
            </td>;
        })
    }
    return <tr key={rowData.rowIndex}
               onContextMenu={(e) => {
                   e.preventDefault()
                   openMenu(e.clientX - 2, e.clientY - 4, entity.index)
               }}
               onClick={() => {
                   if (props.selectRow !== undefined) {
                       props.selectRow(rowData.rowIndex)
                   }
               }}
    >
        { cells }
    </tr>
}

interface PtoItemListWorkspaceBlockProps {
    object: string
    objectData: IndexedTableData<PtoItem>

    dispatch: React.Dispatch<any>
}

interface RowContextMenuState {
    open: boolean
    mouseX: number;
    mouseY: number;
    entityIndex: number
}

const PtoItemListWorkspaceBlock = (props: PtoItemListWorkspaceBlockProps) => {
    const [selectedRow, setSelectedRow] = useState(-1)
    // todo move RowContextMenu to the parent component
    const [rowContextMenu, setRowContextMenu] = useState({
        open: false,
        mouseX: 0,
        mouseY: 0,
    } as RowContextMenuState);
    const openRowContextMenu = useCallback((x: number, y: number, entityIndex: number) => {
        setRowContextMenu(prev => ({...prev, open: true, mouseX: x, mouseY: y, entityIndex: entityIndex}))
    }, []);

    const [objectTotalPrice, setObjectTotalPrice] = useState("0");
    useEffect(() => {
        const total = props.objectData.items
            .map(item => item.data)
            .filter(item => item.materialPrice && item.workPrice && item.quantity)
            .map(item => item.materialPrice.add(item.workPrice).mul(item.quantity))
            .reduce((prev, curr) => prev.add(curr), new Decimal(0));
        setObjectTotalPrice(total.toString())
    }, [props.objectData])

    return <>
        <div className={"workspace-block"}>
            <PtoItemListBlock
                title={ props.object !== DefaultObjectName ? `Объект: ${props.object}` : "Без объекта"}
                tableCss={"pto-item-table"}
                columnSpec={[
                    {name: "index",    title: "№", maxWidth: 60},
                    {name: "name",     title: "Наименование"},
                    {name: "units",    title: "Ед. изм."},
                    {name: "quantity", title: "Объем", format: (value: Decimal) => <DecimalFormat value={value.toString()}/>},
                    {name: "materialPrice", title: "Цена - материалы", format: (value: Decimal) => <MoneyFormat value={value.toString()}/>},
                    {name: "workPrice",     title: "Цена - работа",    format: (value: Decimal) => <MoneyFormat value={value.toString()}/>},
                    {
                        name: "totalMaterialPrice",
                        title: "Стоимость материалов",
                        calc: (entity: PtoItem) => entity.materialPrice.mul(entity.quantity),
                        format: (value: Decimal) => <MoneyFormat value={value.toString()}/>
                    },
                    {
                        name: "totalWorkPrice",
                        title: "Стоимость работ",
                        calc: (entity: PtoItem) => entity.workPrice.mul(entity.quantity),
                        format: (value: Decimal) => <MoneyFormat value={value.toString()}/>
                    },
                ]}
                rowFactory={customRowFactory(props.object, props.dispatch, openRowContextMenu)}
                disableEmptyTableBlock={true}

                data={{
                    isLoaded: true,
                    data: props.objectData.items,
                }}
                selectedRow={selectedRow}
                select={(index: number) => {
                    setSelectedRow(index)
                }}

                actionGroup={
                    <DeleteButton
                        object={props.object !== DefaultObjectName ? `объект: "${props.object}"` : "\"Список работ без объекта\""}
                        callback={() => props.dispatch(deleteObjectAction(props.object))}
                    />
                }
            />
            <Box component="div" className="add-button-container">
                <Button onClick={() => {
                    // todo select new entity
                    props.dispatch(addObjectItemAction(props.object))
                }}>
                    <AddIcon/>
                    Добавить
                </Button>
            </Box>
            <Menu
                open={rowContextMenu.open}
                onClose={() => setRowContextMenu(prev => ({...prev, open: false}))}
                anchorReference="anchorPosition"
                anchorPosition={
                    rowContextMenu.open
                        ? { top: rowContextMenu.mouseY, left: rowContextMenu.mouseX }
                        : undefined
                }
            >

                <MenuItem disabled>
                    <ListItemIcon>
                        <ContentCopyIcon/>
                    </ListItemIcon>
                    Копировать строку
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon>
                        <ContentCutIcon/>
                    </ListItemIcon>
                    Вырезать строку
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon>
                        <ContentPasteIcon/>
                    </ListItemIcon>
                    Вставить строку
                </MenuItem>
                <Divider/>
                <MenuItem disabled>
                    <ListItemIcon><AddIcon/></ListItemIcon>Добавить строку выше
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon><AddIcon/></ListItemIcon>Добавить строку ниже
                </MenuItem>
                <Divider/>
                <MenuItem
                    onClick={() => {
                        props.dispatch(deleteObjectItemAction(props.object, rowContextMenu.entityIndex))
                        setRowContextMenu(prev => ({...prev, open: false}))
                    }}
                >
                    {/* todo show dialog*/}
                    <ListItemIcon>
                        <DeleteIcon/>
                    </ListItemIcon>
                    Удалить
                </MenuItem>
            </Menu>
        </div>
        <Stack direction={"row"} spacing={1} className={"object-total-price-container"}>
            <div>Стоимость ИТОГО:</div>
            <Chip label={<MoneyFormat value={objectTotalPrice}/>} sx={{fontSize: "inherit"}}/>
        </Stack>
    </>
}

export {PtoItemListWorkspaceBlock}
