import React, {useEffect, useState} from "react";
import {AnyAction} from "redux";

import {MoneyFormat} from "../../../../../../app/ui/formatter";
import {Invoice, Invoices} from "../../../../../../app/client/app/entity/pto";
import {EntityListBlock} from "../../../../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {DeleteButton} from "../../../../../../qdep/components/util/delete_btn/DeleteButton";
import {AddInvoiceButton} from "./AddInvoiceButton";
import {IndexedTableItem} from "../../../../../../app/client/app/entity/util";
import {InvoiceStatusLabel} from "./InvoiceStatusLabel";
import {deleteInvoice} from "../../../data/invoice_reducer";
import {DefaultRowFactory} from "../../../../../../qdep/components/entity-list-workspace-block/default/RowFactory";
import {EditInvoiceButton} from "./EditInvoiceButton";
import moment from "moment";
import {Chip, Stack} from "@mui/material";
import Decimal from "decimal.js-light";


const PtoInvoiceListBlock = EntityListBlock

interface PtoInvoiceListProps {
    invoices: Invoices
    dispatch: React.Dispatch<AnyAction>
}

const PtoInvoiceList = (props: PtoInvoiceListProps) => {
    const [selectedRow, setSelectedRow] = useState(-1)
    const [selectedInvoice, setSelectedInvoice] = useState(undefined as IndexedTableItem<Invoice> | undefined);
    useEffect(() => {
        if (selectedRow >= 0 && selectedRow < props.invoices.items.length) {
            setSelectedInvoice(props.invoices.items[selectedRow])
        } else {
            setSelectedInvoice(undefined)
        }
    }, [selectedRow, props.invoices.items])

    const [invoiceTotalPrice, setInvoiceTotalPrice] = useState("0");
    useEffect(() => {
        const total = props.invoices.items
            .map(item => item.data.value)
            .reduce((prev, curr) => prev.add(curr), new Decimal(0));
        setInvoiceTotalPrice(total.toString())
    }, [props.invoices.items])

    return <>
        <div className={"workspace-block"}>
            <PtoInvoiceListBlock
                title={"Счета"}
                titleDetail={`(${props.invoices.items.length})`}

                columnSpec={[
                    {name: "index", title: "№", maxWidth: 60},
                    {name: "name",  title: "Наименование"},
                    {name: "date",  title: "Дата", format: (value: moment.Moment) => value !== undefined ? value.format('DD.MM.YYYY'): ""},
                    {
                        name: "value",
                        title: "Сумма",
                        format: (value: IndexedTableItem<Invoice>) => <MoneyFormat value={value.toString()}/>
                    },
                    {name: "status",  title: "Статус", format: (value: string) => <InvoiceStatusLabel value={value}/>},
                    {name: "comment", title: "Примечание"},
                ]}
                rowFactory={DefaultRowFactory}

                selectedRow={selectedRow}
                select={(index: number) => setSelectedRow(index)}

                data={{
                    isLoaded: true,
                    data: props.invoices.items.map(item => ({
                        index: item.rowIndex,
                        name: item.data.name,
                        date: item.data.date,
                        value: item.data.value,
                        status: item.data.status,
                        comment: item.data.comment,
                    })),
                }}

                actionGroup={
                    <>
                        <AddInvoiceButton dispatch={props.dispatch}/>
                        <EditInvoiceButton
                            disabled={selectedInvoice === undefined}
                            invoice={selectedInvoice}
                            dispatch={props.dispatch}
                        />
                        <DeleteButton
                            disabled={selectedInvoice === undefined}
                            object={selectedInvoice === undefined ? "" : `счет: "${selectedInvoice.data.name || "-"}"`}
                            callback={() => {
                                props.dispatch(deleteInvoice(selectedRow))
                                setSelectedRow(-1)
                            }}
                        />
                    </>
                }
            />
        </div>
        <Stack direction={"row"} spacing={1} className={"invoice-tab-total-container"}>
            <div>ИТОГО:</div>
            <Chip label={<MoneyFormat value={invoiceTotalPrice}/>} sx={{fontSize: "inherit"}}/>
        </Stack>
    </>
}

export {PtoInvoiceList}
