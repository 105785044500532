import {AnyAction} from "redux";
import {DefaultObjectName, Pto, PtoItem} from "../../../../app/client/app/entity/pto";
import {IndexedTableItem} from "../../../../app/client/app/entity/util";
import {PtoEditorPage} from "./editor_reducer";


export enum PtoEditorActionTypes {
    GET_ENTITY_REQUEST           = 'WORKSPACE.PTO_EDITOR.GET_ENTITY_REQUEST',
    GET_ENTITY_REQUEST_SUCCEEDED = 'WORKSPACE.PTO_EDITOR.GET_ENTITY_REQUEST.SUCCEEDED',
    GET_ENTITY_REQUEST_FAILED    = 'WORKSPACE.PTO_EDITOR.GET_ENTITY_REQUEST.FAILED',

    CREATE_ENTITY_REQUEST           = 'WORKSPACE.PTO_EDITOR.CREATE_ENTITY_REQUEST',
    CREATE_ENTITY_REQUEST_SUCCEEDED = 'WORKSPACE.PTO_EDITOR.CREATE_ENTITY_REQUEST.SUCCEEDED',
    CREATE_ENTITY_REQUEST_FAILED    = 'WORKSPACE.PTO_EDITOR.CREATE_ENTITY_REQUEST.FAILED',

    UPDATE_ENTITY_REQUEST           = 'WORKSPACE.PTO_EDITOR.UPDATE_ENTITY_REQUEST',
    UPDATE_ENTITY_REQUEST_SUCCEEDED = 'WORKSPACE.PTO_EDITOR.UPDATE_ENTITY_REQUEST.SUCCEEDED',
    UPDATE_ENTITY_REQUEST_FAILED    = 'WORKSPACE.PTO_EDITOR.UPDATE_ENTITY_REQUEST.FAILED',

    ADD_OBJECT         = "ADD_OBJECT",
    DELETE_OBJECT      = "DELETE_OBJECT",
    ADD_OBJECT_ITEM    = "ADD_OBJECT_ITEM",
    UPDATE_OBJECT_ITEM = "UPDATE_OBJECT_ITEM",
    DELETE_OBJECT_ITEM = "DELETE_OBJECT_ITEM",

    EDITOR_OPEN = "WORKSPACE.PTO_EDITOR.OPEN",
    EDITOR_SET_ENTITY_ID = "WORKSPACE.PTO_EDITOR.SET_ENTITY_ID",
    EDITOR_LOADER = "WORKSPACE.PTO_EDITOR.LOADER",
    EDITOR_UPDATE_ENTITY = "WORKSPACE.PTO_EDITOR.UPDATE_ENTITY",
    EDITOR_CLEAR = "WORKSPACE.PTO_EDITOR.CLEAR",
}

function getEntityRequest(entityId: number): AnyAction {
    return {
        type: PtoEditorActionTypes.GET_ENTITY_REQUEST,
        entityId: entityId,
    }
}

function getEntityRequestSucceeded(entity: Pto): AnyAction {
    return {
        type: PtoEditorActionTypes.GET_ENTITY_REQUEST_SUCCEEDED,
        entity: entity,
    }
}

function getEntityRequestFailed(): AnyAction {
    return {
        type: PtoEditorActionTypes.GET_ENTITY_REQUEST_FAILED
    }
}

function createEntityRequest(entity: Pto): AnyAction {
    return {
        type: PtoEditorActionTypes.CREATE_ENTITY_REQUEST,
        entity: entity,
    }
}

function createEntityRequestSucceeded(entity: Pto): AnyAction {
    return {
        type: PtoEditorActionTypes.CREATE_ENTITY_REQUEST_SUCCEEDED,
        entity: entity,
    }
}

function createEntityRequestFailed(): AnyAction {
    return {
        type: PtoEditorActionTypes.CREATE_ENTITY_REQUEST_FAILED,
    }
}

function updateEntityRequest(entity: Pto): AnyAction {
    return {
        type: PtoEditorActionTypes.UPDATE_ENTITY_REQUEST,
        entity: entity,
    }
}

function updateEntityRequestSucceeded(entity: Pto): AnyAction {
    return {
        type: PtoEditorActionTypes.UPDATE_ENTITY_REQUEST_SUCCEEDED,
        entity: entity,
    }
}

function updateEntityRequestFailed(): AnyAction {
    return {
        type: PtoEditorActionTypes.UPDATE_ENTITY_REQUEST_FAILED,
    }
}

function addObjectAction(objectName: string): AnyAction {
    return {
        type: PtoEditorActionTypes.ADD_OBJECT,
        name: objectName,
    }
}

function addDefaultObjectAction(): AnyAction {
    return {
        type: PtoEditorActionTypes.ADD_OBJECT,
        name: DefaultObjectName,
    }
}

function deleteObjectAction(objectName: string): AnyAction {
    return {
        type: PtoEditorActionTypes.DELETE_OBJECT,
        name: objectName,
    }
}

function addObjectItemAction(objectName: string): AnyAction {
    return {
        type: PtoEditorActionTypes.ADD_OBJECT_ITEM,
        objectName: objectName,
    }
}

function updateObjectItemAction(objectName: string, entity: IndexedTableItem<PtoItem>): AnyAction {
    return {
        type: PtoEditorActionTypes.UPDATE_OBJECT_ITEM,
        objectName: objectName,
        entity: entity
    }
}

function deleteObjectItemAction(objectName: string, entityIndex: number): AnyAction {
    return {
        type: PtoEditorActionTypes.DELETE_OBJECT_ITEM,
        objectName: objectName,
        entityIndex: entityIndex
    }
}

function openEditor(entityId: number | undefined): AnyAction {
    return {
        type: PtoEditorActionTypes.EDITOR_OPEN,
        entityId: entityId,
    }
}

function setEntityId(entityId: number | undefined): AnyAction {
    return {
        type: PtoEditorActionTypes.EDITOR_SET_ENTITY_ID,
        entityId: entityId,
    }
}

function updateEditorStatus(value: PtoEditorPage): AnyAction {
    return {
        type: PtoEditorActionTypes.EDITOR_LOADER,
        value: value,
    }
}

function updateEditorState(data: Partial<Pto>): AnyAction {
    return {
        type: PtoEditorActionTypes.EDITOR_UPDATE_ENTITY,
        data: data,
    }
}

function clearEditor(): AnyAction {
    return {
        type: PtoEditorActionTypes.EDITOR_CLEAR,
    }
}

export {
    getEntityRequest, getEntityRequestSucceeded, getEntityRequestFailed,
    createEntityRequest, createEntityRequestSucceeded, createEntityRequestFailed,
    updateEntityRequest, updateEntityRequestSucceeded, updateEntityRequestFailed,
    addObjectAction, addDefaultObjectAction, deleteObjectAction,
    addObjectItemAction, updateObjectItemAction, deleteObjectItemAction,
    openEditor, setEntityId, updateEditorStatus, updateEditorState, clearEditor,
}