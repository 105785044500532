import {defaultPtoListState, PtoListState} from "./state";
import {AnyAction} from "redux";
import {PtoListActionTypes} from "./action";
import {
    updateDataReducer,
    updateSettingsReducer
} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {PtoEditorActionTypes} from "../../editor/data/action";

function addEntity(state: PtoListState, action: AnyAction): PtoListState {
    return {
        ...state,
        data: {
            ...state.data,
            data: [
                ...state.data.data,
                action.entity,
            ]
        }
    }
}

function updateEntity(state: PtoListState, action: AnyAction): PtoListState {
    const entity = action.entity;
    const index = state.data.data.findIndex(item => item.id === entity.id);

    let data = [...state.data.data]
    data[index] = entity
    return {
        ...state,
        data: {
            ...state.data,
            data: data,
        }
    }
}

function selectRowReducer(state: PtoListState, action: AnyAction): PtoListState {
    let selectedRow = action.index
    if (state.selectedRow === selectedRow) {
        selectedRow = undefined
    }
    return {
        ...state,
        selectedRow: selectedRow
    };
}

function ptoListReducer(state: PtoListState = defaultPtoListState, action: AnyAction): PtoListState {
    switch (action.type) {
        case PtoListActionTypes.START_LOADING:
            return {...state, isLoading: true}
        case PtoListActionTypes.LIST_REQUEST_FAILED:
            return {...state, isLoading: false}
        case PtoListActionTypes.LIST_REQUEST_SUCCEEDED:
            return updateDataReducer(state, action)
        case PtoListActionTypes.SELECT_ROW:
            return selectRowReducer(state, action)
        case PtoListActionTypes.UPDATE_SETTINGS:
            return updateSettingsReducer(state, action)
        case PtoEditorActionTypes.CREATE_ENTITY_REQUEST_SUCCEEDED:
            return addEntity(state, action)
        case PtoEditorActionTypes.UPDATE_ENTITY_REQUEST_SUCCEEDED:
            return updateEntity(state, action)
    }
    return state
}

export {ptoListReducer}
