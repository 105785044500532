import React from "react";
import {connect} from "react-redux";

import SummarizeIcon from '@mui/icons-material/Summarize';
import {ListItemIcon, MenuItem} from "@mui/material";
import {useHistory} from "react-router-dom";
import {AppState} from "../../../../../app/state/state";

interface ReportActionButtonProps {
    ptoId: number | undefined
}

const ReportActionButton = (props: ReportActionButtonProps) => {
    const history = useHistory();
    return <MenuItem
        key="preview"
        disabled={props.ptoId === undefined}
        onClick={() => {
            if (props.ptoId !== undefined) {
                history.push(`/pto/${props.ptoId}/report`);
            }
        }}
    >
        <ListItemIcon>
            <SummarizeIcon/>
        </ListItemIcon>
        Предпросмотр
    </MenuItem>
}

const mapStateToProps = (state: AppState): ReportActionButtonProps => {
    let ptoId: number | undefined
    if (state.pto.selectedRow !== undefined && state.pto.selectedRow >= 0) {
        ptoId = state.pto.data.data[state.pto.selectedRow].id
    }
    return {
        ptoId: ptoId
    };
}

const ConnectedReportActionButton = connect(mapStateToProps)(ReportActionButton)

export {ConnectedReportActionButton as ReportActionButton}