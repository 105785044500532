import Decimal from "decimal.js-light";
import moment from "moment";
import {IndexedTableData} from "./util";

interface Pto {
    id: number
    contract_number: string
    contract_date: moment.Moment

    customer: string      // Заказчик
    contractor: string    // Подрядчик
    settlement: string    // Поселок

    create_date: moment.Moment
    update_date: moment.Moment

    prepayment:    Decimal
    pto_total:     Decimal
    kc_total:      Decimal
    invoice_total: Decimal

    total_warnings: number
    kc_refs: number

    items: Items
    invoices: Invoices
}

const DefaultObjectName = "DEFAULT_OBJECT"

interface Items {
    hasDefaultObject: boolean
    objects: Objects
}

interface Objects {
    [name: string]: IndexedTableData<PtoItem>
}

interface PtoItem{
    index: number
    name: string
    units: string
    quantity: Decimal
    materialPrice: Decimal
    workPrice: Decimal
}

type Invoices = IndexedTableData<Invoice>

interface Invoice {
    name: string
    date: moment.Moment | undefined
    value: Decimal
    status: string
    comment: string
}

function NewPto(): Pto {
    return {
        id: -1,
        contract_number: "",
        contract_date: moment(),

        customer: "",
        contractor: "",
        settlement: "",

        prepayment: new Decimal(0),
        pto_total: new Decimal(0),
        kc_total: new Decimal(0),
        invoice_total: new Decimal(0),

        total_warnings: 0,
        kc_refs: 0,

        items: {
            hasDefaultObject: false,
            objects: {},
        },
        invoices:  {
            seq: 1,
            items: [],
        },

        create_date: moment(),
        update_date: moment(),
    }
}

export {NewPto, DefaultObjectName}
export type {
    Pto,
    Items, Objects, PtoItem,
    Invoice, Invoices,
}
