import React from "react";

import {Divider, IconButton, Tooltip} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";

import {DeleteButton} from "../../../../../../qdep/components/util/delete_btn/DeleteButton";
import {deleteEntityRequest, signEntityRequest} from "../../../data/action";

import styles from './PreviewControlPane.module.css'
import {useDispatch} from "react-redux";


interface PreviewControlPaneProps {
    documentId: string
    documentName: string
    documentSignStatus: boolean
}

const PreviewControlPane = (props: PreviewControlPaneProps) => {
    const dispatch = useDispatch();

    return <div className={styles.control_container}>
        <Tooltip title={"Обновить Документ"} placement={"left"}>
            <IconButton disabled>
                <RefreshIcon/>
            </IconButton>
        </Tooltip>
        <Divider sx={{margin:"4px 2px"}}/>
        <Tooltip title={"Статус: Подписано"} placement={"left"}>
            <IconButton
                disabled={props.documentSignStatus}
                onClick={() => dispatch(signEntityRequest(props.documentId, !props.documentSignStatus))}
            >
                <BookmarkAddedIcon/>
            </IconButton>
        </Tooltip>
        <Tooltip title={"Статус: Не подписано"} placement={"left"}>
            <IconButton
                disabled={!props.documentSignStatus}
                onClick={() => dispatch(signEntityRequest(props.documentId, !props.documentSignStatus))}
            >
                <BookmarkRemoveIcon/>
            </IconButton>
        </Tooltip>
        <Divider sx={{margin:"4px 2px"}}/>
        <DeleteButton
            object={props.documentName}
            callback={() => dispatch(deleteEntityRequest(props.documentId))}
        />
    </div>
}

export {PreviewControlPane}
