import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import Decimal from "decimal.js-light";
import moment from "moment";

import {Divider, ListItemIcon, MenuItem, MenuList} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

import {
    EntityListBlock,
    EntityListBlockAction,
    EntityListBlockProps
} from "../../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {AppState} from "../../../../app/state/state";
import {defaultPtoListState} from "../data/state";
import {EntityListSettings} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {listRequest, listSelectRow, updateSettings} from "../data/action";
import {formatDate, MoneyFormat} from "../../../../app/ui/formatter";
import {ReportActionButton} from "./action/ReportActionButton";
import {CreateActionButton} from "./action/CreateActionButton";
import {ActionGroupButton} from "../../../../qdep/components/entity-list-workspace-block/ActionGroupButton";
import {EditActionButton} from "./action/EditActionButton";
import {DefaultRowFactory} from "../../../../qdep/components/entity-list-workspace-block/default/RowFactory";


const mapStateToProps = (state: AppState): EntityListBlockProps => {
    const data = state.pto?.data || [];
    return {
        title: "Договоры",
        titleDetail: data.data !== undefined ? `(${data.data.length})`: "",

        selectedRow: state.pto?.selectedRow,

        settings: state.pto?.settings || defaultPtoListState.settings,
        data: data,
        isLoading: state.pto.isLoading,

        columnSpec: [
            {name: "id", title: "ID"},
            {name: "contract_number", title: "Номер договора"},
            {name: "contract_date", title: "Дата договора", format: (value: moment.Moment) => value.format('DD.MM.YYYY')},
            {name: "customer", title: "Заказчик"},
            {name: "contractor", title: "Подрядчик"},
            {name: "settlement", title: "Поселок"},
            {name: "create_date", title: "Дата создания", format: formatDate},
            {name: "update_date", title: "Последнее обновление", format: formatDate},
            {name: "prepayment", title: "Аванс",     format: (value: Decimal) => <MoneyFormat value={value.toString()}/>},
            {name: "pto_total",  title: "Стоимость Договора", format: (value: Decimal) => <MoneyFormat value={value.toString()}/>},
            {name: "kc_total",   title: "Стоимость КС",  format: (value: Decimal) => <MoneyFormat value={value.toString()}/>},
            {name: "invoice_total",   title: "Стоимость Счета",  format: (value: Decimal) => <MoneyFormat value={value.toString()}/>},
            {name: "kc_refs", title: "Связанных КС"},
            {name: "total_warnings", title: "Ошибки"},
        ],
        rowFactory: DefaultRowFactory,
        actionGroup: <ActionGroupButton menu={
            <MenuList id="split-button-menu">
                <CreateActionButton/>
                <EditActionButton/>
                <Divider/>
                <ReportActionButton/>

                <MenuItem
                    key="download"
                    disabled={true}
                >
                    <ListItemIcon>
                        <DownloadIcon/>
                    </ListItemIcon>
                    Скачать
                </MenuItem>
            </MenuList>
        }/>
    }
}

const mapDispatchToProps = (dispatch: Dispatch): EntityListBlockAction => {
    return {
        refresh: (limit: number) => dispatch(listRequest(limit)),
        select: (index: number) => dispatch(listSelectRow(index)),
        updateSettings: (settings: EntityListSettings) => dispatch(updateSettings(settings)),
    }
}

const PtoListBlock = connect(mapStateToProps, mapDispatchToProps)(EntityListBlock)

const PtoListWorkspaceBlock = () => {
    return <div className={"workspace-block"}>
        <PtoListBlock key={"pto-list-block"}/>
    </div>
}

export {PtoListWorkspaceBlock}