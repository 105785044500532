import React from "react";
import {useDispatch} from "react-redux";
import {useMount} from "react-use";
import {useParams} from "react-router-dom";

import {Pto} from "../../../../app/client/app/entity/pto";
import {PtoEditor} from "./PtoEditor";
import {openEditor, updateEntityRequest} from "../data/action";

import './../../../../qdep/components/entity-editor/EntityEditor.css'


interface PtoEditorParam {
    id: string
}

const PtoEditorWorkspace = () => {
    const {id} = useParams<PtoEditorParam>();
    const dispatch = useDispatch();
    useMount(() => {
        let ptoId: number | undefined = parseInt(id, 10);
        if (isNaN(ptoId)) {
            ptoId = undefined
        }
        dispatch(openEditor(ptoId))
    })

    return <>
        <div className={"header"}>
            Редактор Договора
        </div>
        <PtoEditor
            saveAction={(entity: Pto) => updateEntityRequest(entity)}
        />
    </>;
}

export {PtoEditorWorkspace}