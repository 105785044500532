import {QSimpleTable} from "@qdep/react-table";
import React from "react";
import {KcItem} from "../../../../../../app/client/app/entity/kc";
import {QSimpleTableCell} from "@qdep/react-table/dist/component/QSimpleTable";
import _ from "lodash";
import {MoneyFormat} from "../../../../../../app/ui/formatter";
import {IssueType} from "../../../../../../app/client/app/entity/document_issue";
import {DefaultObjectName} from "../../../../../../app/client/app/entity/pto";

interface KcDocumentPreviewItemsProps {
    items: KcItem[] | undefined
}

const KcDocumentPreviewItems = (props: KcDocumentPreviewItemsProps) => {
    return <div className={"kc-document-preview-container"}>
        <div className={"entity-list-table-container"}>
            <QSimpleTable
                tableCss={"kc-items-table"}
                data={formatTableData(props.items)}
            >
                <tr>
                    <th>Номер</th>
                    <th>Позиция по смете</th>
                    <th style={{minWidth: "200px"}}>Наименование работ</th>
                    <th>Ед. изм.</th>
                    <th>Объем</th>
                    <th>Цена - материалы</th>
                    <th>Цена - работа</th>
                    <th>Стоимость материалов</th>
                    <th>Стоимость работ</th>
                </tr>
            </QSimpleTable>
        </div>
    </div>
}


function formatTableData(items: KcItem[] | undefined): QSimpleTableCell[][] {
    if (items === undefined || !items.length) {
        return [[{data: "Нет записей", colSpan: 7, className: "group-item"}]]
    }

    const itemsByObject = _(items)
        .groupBy(item => item.object)
        .map((objectItems, object) => ({object, objectItems}))
        .value();
    const result = _(itemsByObject).flatMap(group => {
        return [
            [{data: group.object === DefaultObjectName ? "Без объекта": group.object, colSpan: 9, className: "group-item"}],
            ...group.objectItems.sort((a, b) => a.index - b.index).map(formatKcItem)
        ]
    });

    return result.value()
}

function formatKcItem(item: KcItem): QSimpleTableCell[] {
    const hasUnknownItemIssue = item.issue_types.hasOwnProperty(IssueType.KC2_UNKNOWN_ITEM);
    const hasAnotherItemNameIssue = item.issue_types.hasOwnProperty(IssueType.KC2_ANOTHER_ITEM_NAME);
    const hasAnotherItemUnitIssue = item.issue_types.hasOwnProperty(IssueType.KC2_ANOTHER_ITEM_UNIT);
    const hasIssueTypeKc2AnotherItemQuantity = item.issue_types.hasOwnProperty(IssueType.KC2_ANOTHER_ITEM_QUANTITY);
    const hasIssueTypeKc2AnotherItemMaterialPrice = item.issue_types.hasOwnProperty(IssueType.KC2_ANOTHER_ITEM_MATERIAL_PRICE);
    const hasIssueTypeKc2AnotherItemWorkPrice = item.issue_types.hasOwnProperty(IssueType.KC2_ANOTHER_ITEM_WORK_PRICE);
    const hasWrongItemMaterialTotalIssue = item.issue_types.hasOwnProperty(IssueType.KC2_WRONG_ITEM_TOTAL_MATERIAL_PRICE);
    const hasWrongItemWorkTotalIssue = item.issue_types.hasOwnProperty(IssueType.KC2_WRONG_ITEM_TOTAL_WORK_PRICE);

    return [
        {data: item.index.toString()},
        {data: item.pto_item_index.toString(), className: hasUnknownItemIssue ? "has_warning" : ""},
        {data: item.name, className: hasAnotherItemNameIssue ? "has_warning" : ""},
        {data: item.units, className: hasAnotherItemUnitIssue ? "has_warning" : ""},
        {data: item.quantity.toString(), className: hasIssueTypeKc2AnotherItemQuantity ? "numeric_cell has_issue" : "numeric_cell"},
        {data: <MoneyFormat value={item.material_price.toString()}/>, className: hasIssueTypeKc2AnotherItemMaterialPrice ? "numeric_cell has_issue" : "numeric_cell"},
        {data: <MoneyFormat value={item.work_price.toString()}/>, className: hasIssueTypeKc2AnotherItemWorkPrice ? "numeric_cell has_issue" : "numeric_cell"},
        {data: <MoneyFormat value={item.total_material_price.toString()}/>, className: hasWrongItemMaterialTotalIssue ? "numeric_cell has_issue" : "numeric_cell"},
        {data: <MoneyFormat value={item.total_work_price.toString()}/>, className: hasWrongItemWorkTotalIssue ? "numeric_cell has_issue" : "numeric_cell"},
    ]
}

export {KcDocumentPreviewItems}