import { call, put, takeEvery } from 'redux-saga/effects'
import {AnyAction} from "redux";
import {apiClient} from "../../../../app/client/app/client";
import {Pto} from "../../../../app/client/app/entity/pto";
import {ApiResponse} from "../../../../app/client/types";
import {listRequestFailed, listRequestSucceeded, PtoListActionTypes, startLoading} from "./action";
import {notificationEmit} from "../../../../app/notification/reducer";

function * listRequestSaga (action: AnyAction) {
    yield put(startLoading())
    let response: ApiResponse<Pto[]> = yield call(apiClient.listPto)
    if (!response.errorReason && response.data) {
        yield put(listRequestSucceeded(response.data))
    } else {
        yield put(listRequestFailed())
        yield put(notificationEmit(
            "pto-list-request",
            "error",
            "Не удалось загрузить данные. Попробуйте позже или свяжитесь с администратором.",
        ));
    }
}

export function * watchPtoListWorkspace () {
    yield takeEvery(PtoListActionTypes.LIST_REQUEST, listRequestSaga)
}