import {Pto} from "../../../../app/client/app/entity/pto";
import {EntityListSettingsState} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {DataPiece} from "../../../../app/client/app/entity/util";

interface PtoListState extends EntityListSettingsState {
    data: DataPiece<Pto[]>
    selectedRow?: number
    isLoading?: boolean
}

const defaultPtoListState: PtoListState = {
    data: {isLoaded: false, data: []},
    settings: {
        pageSize: 25,
        columns: {
            "ID": true,
            "Номер договора": true,
            "Дата договора": true,
            "Заказчик": false,
            "Подрядчик": false,
            "Поселок": false,
            "Дата создания": false,
            "Последнее обновление": true,
            "Аванс": true,
            "Стоимость Договора": true,
            "Стоимость КС": false,
            "Стоимость Счета": false,
            "Связанных КС": false,
            "Ошибки": true,
        }
    }
}

export type {PtoListState}
export {defaultPtoListState}