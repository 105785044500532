import React, {useState} from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from "@mui/material";

interface ConfirmDeleteDialogProps {
    object: string
    isOpen: boolean
    close: () => void
    confirm: () => void
}

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
    return <Dialog open={props.isOpen}>
        <DialogTitle>Вы уверены?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Вы уверены, что хотите удалить {props.object}?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Отменить</Button>
            <Button variant="contained" color="secondary"
                    onClick={() => {
                        props.confirm()
                        props.close()
                    }}
            >
                Удалить
            </Button>
        </DialogActions>
    </Dialog>
}

interface DeleteButtonProps {
    disabled?: boolean
    object: string
    callback: () => void
}

const DeleteButton = (props: DeleteButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);
    return <>
        <Tooltip title={"Удалить Документ"} placement={"left"}>
            <IconButton disabled={props.disabled} onClick={() => setShowDialog(true)}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
        <ConfirmDeleteDialog
            isOpen={showDialog}
            close={() => setShowDialog(false)}
            object={props.object}
            confirm={props.callback}
        />
    </>
}

export {DeleteButton}