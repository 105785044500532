import React, {useState} from "react";
import {AnyAction} from "redux";

import AddIcon from "@mui/icons-material/Add";
import {IconButton} from "@mui/material";

import {defaultInvoice, InvoiceEditorDialog} from "./InvoiceEditorDialog";
import {addInvoice} from "../../../data/invoice_reducer";


interface AddInvoiceButtonProps {
    dispatch: React.Dispatch<AnyAction>
}

const AddInvoiceButton = (props: AddInvoiceButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);
    return <>
        <IconButton
            onClick={() => setShowDialog(true)}
        >
            <AddIcon/>
        </IconButton>
        <InvoiceEditorDialog
            isOpen={showDialog}
            close={() => setShowDialog(false)}

            invoice={defaultInvoice}
            apply={invoice => props.dispatch(addInvoice(invoice))}
        />
    </>
}

export {AddInvoiceButton}