import React from "react";

import {Pto} from "../../../../app/client/app/entity/pto";
import {createEntityRequest, openEditor} from "../data/action";
import {PtoEditor} from "./PtoEditor";

import './../../../../qdep/components/entity-editor/EntityEditor.css'
import {useMount} from "react-use";
import {useDispatch} from "react-redux";


const PtoWizardWorkspace = () => {
    const dispatch = useDispatch();
    useMount(() => {
        dispatch(openEditor(undefined))
    })

    return <>
        <div className={"header"}>
            Новый Договор
        </div>
        <PtoEditor
            saveAction={(entity: Pto) => createEntityRequest(entity)}
        />
    </>
}

export {PtoWizardWorkspace}
