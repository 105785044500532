import React from "react";
import {useHistory} from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import {ListItemIcon, MenuItem} from "@mui/material";


const CreateActionButton = () => {
    const history = useHistory();

    return  <MenuItem
        key="create"
        onClick={() => {
            history.push("/pto/create")
        }}
    >
        <ListItemIcon>
            <AddIcon/>
        </ListItemIcon>
        Создать
    </MenuItem>
}

export {CreateActionButton}