import React from "react";

import './Main.css'

class Main extends React.Component<{}, {}> {

    render(): React.ReactNode {
        return <div className={"main-container"}>
                {this.props.children}
        </div>;
    }
}

export { Main }