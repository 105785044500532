import React, {useEffect, useState} from "react";

import {IconButton, Stack} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import {QSimpleTableCell} from "@qdep/react-table/dist/component/QSimpleTable";

import {PtoReportSummaryTable} from "../../../../app/client/app/entity/pto_report";
import {Spacer} from "../../../../qdep/components/util/spacer/Spacer";
import {SummaryTableHeader} from "./SummaryTableHeader";

import './SummaryTable.css'


interface SummaryTableProps {
    data: PtoReportSummaryTable
}

interface SummaryTableGroup {
    index: number
    name: string
    isCollapsed: boolean
}

const SummaryTable = (props: SummaryTableProps) => {
    const [summaryTableGroups, setSummaryTableGroups] = useState(
        props.data.kcInfo.map((item, index) => (
            {index: index, name: item.name, isCollapsed: false} as SummaryTableGroup
        ))
    );
    const [data, setData] = useState<QSimpleTableCell[][]>([])
    useEffect(() => {
        let result: QSimpleTableCell[][] = []
        for (let row of props.data.data) {
            let mappedRow: QSimpleTableCell[] = []
            mappedRow.push(...row.slice(0, 13))

            let fromIndex = 13
            for (let group of summaryTableGroups) {
                let toIndex = fromIndex + 5
                if (!group.isCollapsed) {
                    mappedRow.push(...row.slice(fromIndex, toIndex))
                }
                fromIndex = toIndex
            }

            result.push(mappedRow)
        }
        setData(result)
    }, [summaryTableGroups, props.data])

    return <>
        <SummaryTableHeader objectName={props.data.objectName} kcInfo={props.data.kcInfo}/>
        <div className={"workspace-block"}>
            <div className="entity-list-table-container">
                <table
                    className={"pto-report-summary-table"}
                >
                    <tbody>
                        <tr>
                            <td colSpan={3} className={"header"}>Работы</td>
                            <td colSpan={6} className={"header"}>Договор</td>
                            <td rowSpan={2} className={"header center"}>ИТОГО объем по КС</td>
                            <td rowSpan={2} className={"header center"}>ОСТАТОК объем</td>
                            <td rowSpan={2} className={"header center"}>ИТОГО стоимость материалов по КС</td>
                            <td rowSpan={2} className={"header center"}>ИТОГО стоимость работ по КС</td>
                            { summaryTableGroups.map(item =>
                                <HeaderGroup
                                    {...item}
                                    rowNum={data.length}
                                    onClick={(index: number, isCollapsed: boolean) => {
                                        setSummaryTableGroups(prev => {
                                            const state = [...prev];
                                            state[index].isCollapsed = isCollapsed
                                            return state
                                        })
                                    }}
                                />
                            )}
                        </tr>
                        <tr>
                            <td className={"header"}>№</td>
                            <td className={"header"}>Наименование работ</td>
                            <td className={"header"}>Ед. изм.</td>
                            <td className={"header"}>Объем</td>
                            <td className={"header"}>Цена - материалы</td>
                            <td className={"header"}>Цена - работа</td>
                            <td className={"header"}>Стоимость материалов</td>
                            <td className={"header"}>Стоимость работ</td>
                            <td className={"header"}>Стоимость итого</td>

                            { summaryTableGroups.map(item => {
                                if (item.isCollapsed) {
                                    return <></>
                                } else {
                                    return <>
                                        <td className={"header"}>Объем</td>
                                        <td className={"header"}>Цена - материалы</td>
                                        <td className={"header"}>Цена - работа</td>
                                        <td className={"header"}>Стоимость материалов</td>
                                        <td className={"header"}>Стоимость работ</td>
                                    </>
                                }
                            })}
                        </tr>

                        { data.map((row, rowIndex) =>
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) =>
                                    <td key={cellIndex} className={cell.className}>
                                        { cell.data }
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </>;
}

interface HeaderGroupProps extends SummaryTableGroup {
    rowNum: number
    onClick: (index: number, isCollapsed: boolean) => void
}

const HeaderGroup = (props: HeaderGroupProps) => {
    if (props.isCollapsed) {
        return <td rowSpan={2 + props.rowNum} className={"header collapsed"}>
            <Stack direction={"column"} alignItems={"center"} gap={"12px"}>
                <IconButton sx={{padding: "2px"}} onClick={() => props.onClick(props.index, false)}>
                    <AddIcon/>
                </IconButton>
                <span className={"rotated_document_name"} style={{maxHeight: props.rowNum * 30 + 60}}>
                    { props.name.replace("№", "No") }
                </span>
            </Stack>
        </td>
    }

    return <td colSpan={5} className={"header"}>
        <Stack direction={"row"} alignItems={"center"}>
            <span>{props.name}</span>
            <Spacer/>
            <IconButton sx={{padding: 0}} onClick={() => props.onClick(props.index, true)}>
                <RemoveIcon/>
            </IconButton>
        </Stack>
    </td>;
}

export {SummaryTable}
