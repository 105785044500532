import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {CircularProgress} from "@mui/material";

import {PtoReportBody} from "./PtoReportBody";
import {PtoReport} from "../../../../app/client/app/entity/pto_report";
import {apiClient} from "../../../../app/client/app/client";

interface RouteParams {
    id: string
}

const PtoReportWorkspace = () => {
    const { id } = useParams<RouteParams>()
    const [title, setTitle] = useState(`Отчет: Договор ${id}`)
    const [data, setData] = useState(undefined as PtoReport | undefined);

    useEffect(() => {
        apiClient
            .getPtoReportData(parseInt(id))
            .then(response => {
                if (response.data !== undefined) {
                    setData(response.data);
                    setTitle(`Отчет: ${response.data.documentName}`)
                }
            })
    }, [id])

    return <>
        <div className={"header"}>
            { title }
        </div>
        {data !== undefined ? <PtoReportBody data={data}/> : <CircularProgress color="secondary"/>}
    </>
}

export {PtoReportWorkspace}