import React from 'react';
import {Route, Switch} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'

import {history} from './app/state/store'
import {LoginPage} from "./app/page/LoginPage";
import {MainPage} from "./app/page/MainPage";
import useNotifier from "./app/notification/useNotifier";

import './App.css';
import './app/ui/entity-registry-table.css'


const appVersionName = process.env.REACT_APP_VERSION_NAME || "latest"

const App = () => {
  useNotifier()

  return <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/login">
        <LoginPage appVersion={appVersionName}/>
      </Route>
      <Route>
        <MainPage appVersion={appVersionName}/>
      </Route>
    </Switch>
  </ConnectedRouter>
}

export {App};
