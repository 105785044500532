import {PtoItem, Items, DefaultObjectName} from "../../../../app/client/app/entity/pto";
import {AnyAction} from "redux";
import Decimal from "decimal.js-light";
import {PtoEditorActionTypes} from "./action";
import {IndexedTableItem} from "../../../../app/client/app/entity/util";


function addObjectReducer(state: Items, action: AnyAction): Items {
    const objectName = action.name;
    return {
        hasDefaultObject: state.hasDefaultObject || objectName === DefaultObjectName,
        objects: {
            ...state.objects,
            [objectName]: {
                seq: 1,
                items: []
            }
        }
    }
}

function deleteObjectReducer(state: Items, action: AnyAction): Items {
    const objectName = action.name
    if (objectName in state.objects) {
        let result = {...state.objects}
        delete result[objectName];
        return {
            hasDefaultObject: objectName === DefaultObjectName ? false : state.hasDefaultObject,
            objects: result
        }
    }
    return state
}

function addObjectItemReducer(state: Items, action: AnyAction): Items {
    const objectName = action.objectName

    if (objectName in state.objects) {
        let newObject = {...state.objects[objectName]}
        const maxIndex = newObject.items.length === 0 ? 0 : Math.max(...newObject.items.map((item) => item.data.index));
        newObject.items.push({
            rowIndex: newObject.seq,
            data: {
                index: maxIndex + 1,
                name: "",
                units: "-",
                quantity: new Decimal(0),
                materialPrice: new Decimal(0),
                workPrice: new Decimal(0),
            },
        })
        newObject.seq++

        return {
            ...state,
            objects: {
                ...state.objects,
                [objectName]: newObject
            }
        }
    }

    return state
}

function updateObjectItemReducer(state: Items, action: AnyAction): Items {
    const objectName = action.objectName
    const entity = action.entity as IndexedTableItem<PtoItem>

    if (objectName in state.objects) {
        let newObject = {...state.objects[objectName]};
        const itemIndex = newObject.items.findIndex((item) => item.rowIndex === entity.rowIndex);
        if (itemIndex > -1) {
            newObject.items[itemIndex] = entity;
        }

        return {
            ...state,
            objects: {
                ...state.objects,
                [objectName]: newObject
            }
        }
    }

    return state
}

function deleteObjectItemReducer(state: Items, action: AnyAction): Items {
    const objectName = action.objectName
    const entityIndex = action.entityIndex

    if (objectName in state.objects) {
        let newObject = {...state.objects[objectName]};
        const itemIndex = newObject.items.findIndex((item) => item.data.index === entityIndex);
        if (itemIndex > -1) {
            newObject.items.splice(itemIndex, 1)
        }
        return {
            ...state,
            objects: {
                ...state.objects,
                [objectName]: newObject
            }
        }
    }

    return state
}

function PtoEditorItemsReducer(state: Items, action: AnyAction): Items {
    switch (action.type) {
        case PtoEditorActionTypes.ADD_OBJECT:
            return addObjectReducer(state, action)
        case PtoEditorActionTypes.DELETE_OBJECT:
            return deleteObjectReducer(state, action)
        case PtoEditorActionTypes.ADD_OBJECT_ITEM:
            return addObjectItemReducer(state, action)
        case PtoEditorActionTypes.UPDATE_OBJECT_ITEM:
            return updateObjectItemReducer(state, action)
        case PtoEditorActionTypes.DELETE_OBJECT_ITEM:
            return deleteObjectItemReducer(state, action)
    }
    return state
}

export {PtoEditorItemsReducer}