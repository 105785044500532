import {Label} from "../../../../../../qdep/components/util/label/Label";

interface InvoiceStatusLabelProps {
    value: string
}

const InvoiceStatusLabel = (props: InvoiceStatusLabelProps) => {
    switch (props.value) {
        case "PAID":
            return <Label text={"Оплачен"} variant={"success"}/>
        case "WAIT":
            return <Label text={"Не оплачен"} variant={"error"}/>
        default:
            return <Label text={props.value} variant={"info"}/>
    }
}

export {InvoiceStatusLabel}

