interface ApiClientProps {
    baseUrl: string,
}

enum ErrorReason {
    UNKNOWN_ERROR,
    TOKEN_EXPIRED,
    WRONG_USER_OR_PASS,
    INVALID_TOKEN,
    TIMEOUT,
    SERVER_SIDE,
}

interface ApiResponse<T> {
    data?: T,
    errorReason?: ErrorReason
}

export type { ApiResponse, ApiClientProps }
export { ErrorReason }