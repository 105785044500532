import axios, {AxiosInstance, AxiosResponse} from "axios";
import moment from "moment/moment";
import Decimal from "decimal.js-light";

import {Pto, Objects, DefaultObjectName} from "./entity/pto";
import {Kc, KcItem} from "./entity/kc";
import {PtoReport} from "./entity/pto_report";
import {DocumentIssue, IssueType} from "./entity/document_issue";
import {ApiClientProps, ApiResponse, ErrorReason} from "../types";

type SyncPeriod = "3hour" | "1day" | "7day"

class ApiClient {
    readonly config: ApiClientProps
    readonly client: AxiosInstance

    constructor(props: ApiClientProps, client: AxiosInstance) {
        this.config = props
        this.client = client

        this.createPto = this.createPto.bind(this)
        this.updatePto = this.updatePto.bind(this)
        this.listPto   = this.listPto.bind(this)
        this.getDetailedPtoById = this.getDetailedPtoById.bind(this)
        this.getPtoReportData   = this.getPtoReportData.bind(this)

        this.listKc    = this.listKc.bind(this)
        this.getKc     = this.getKc.bind(this)
        this.signKc    = this.signKc.bind(this)
        this.deleteKc  = this.deleteKc.bind(this)
        this.syncKc    = this.syncKc.bind(this)
    }

    entityList<T>(entityName: string, config: ApiClientProps, mapper: (item: Record<string, any>) => T, limit?: number): Promise<ApiResponse<T[]>> {
        return this.client
            .get(`/v1/${entityName}`, {
                baseURL: config.baseUrl,
                params: {
                    limit: limit || 25,
                }
            })
            .then((response: AxiosResponse): ApiResponse<T[]> => {
                switch (response.status) {
                    case 200:
                        return {
                            data: (response.data as Array<Record<string, any>>).map(mapper)
                        };
                    default:
                        return {
                            errorReason: ErrorReason.UNKNOWN_ERROR,
                        }
                }
            }).catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    // pto region
    createPto(entity: Pto): Promise<ApiResponse<Pto>> {
        return this.client
            .post(
                "/v1/pto",
                {
                    ...entity,
                    contract_date: entity.contract_date.format("DD.MM.YYYY"),
                    items: Object.fromEntries(
                        Object
                            .keys(entity.items.objects)
                            .map(object => [object, entity.items.objects[object].items.map(item => item.data)])
                    ),
                    invoices: entity.invoices.items.map(item => item.data),
                },
                {baseURL: this.config.baseUrl}
            )
            .then((response: AxiosResponse): ApiResponse<Pto> => {
                if (response.status === 200 || response.status === 201) {
                    return {
                        data: {
                            ...entity,
                            ...parsePto(response.data),
                        }
                    }
                } else {
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR,
                    }
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    updatePto(entity: Pto): Promise<ApiResponse<Pto>> {
        return this.client
            .post(
                `/v1/pto/${entity.id}`,
                {
                    ...entity,
                    contract_date: entity.contract_date.format("DD.MM.YYYY"),
                    items: Object.fromEntries(
                        Object
                            .keys(entity.items.objects)
                            .map(object => [object, entity.items.objects[object].items.map(item => item.data)])
                    ),
                    invoices: entity.invoices.items.map(item => item.data),
                },
                {baseURL: this.config.baseUrl}
            )
            .then((response: AxiosResponse): ApiResponse<Pto> => {
                if (response.status === 200 || response.status === 201) {
                    return {
                        data: {
                            ...entity,
                            ...parsePto(response.data),
                        }
                    }
                } else {
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR,
                    }
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    listPto(): Promise<ApiResponse<Pto[]>> {
        return this.entityList("pto", this.config, (item: Record<string, any>) => {
            return {
                ...parsePto(item),
                items: {
                    hasDefaultObject: false,
                    objects: {},
                },
                invoices: {
                    seq: 1,
                    items: [],
                },
            }
        });
    }

    getDetailedPtoById(id: number): Promise<ApiResponse<Pto>> {
        return this.client
            .get(`/v1/pto/${id}`, {baseURL: this.config.baseUrl})
            .then((response: AxiosResponse): ApiResponse<Pto> => {
                if (response.status === 200) {
                    let objects: Objects = {}
                    const rawObjects = response.data.items as Record<string, Array<Record<string, any>>> || {};
                    Object.keys(rawObjects)
                        .forEach(object => {
                            const rawObject = rawObjects[object];
                            objects[object] = {
                                seq: rawObject.length + 1,
                                items: rawObject.map((item, index) => ({
                                    rowIndex: index + 1,
                                    data: {
                                        index: item.index,
                                        name: item.name,
                                        units: item.units,
                                        quantity: toDecimal(item.quantity),
                                        materialPrice: toDecimal(item.materialPrice),
                                        workPrice: toDecimal(item.workPrice),
                                    }
                                }))
                            }
                        })
                    const defaultObject = Object.keys(rawObjects).find(object => DefaultObjectName === object);
                    let rawInvoices = response.data.invoices as Array<Record<string, any>> || [];
                    return {
                        data: {
                            ...parsePto(response.data),
                            items: {
                                hasDefaultObject: defaultObject !== undefined,
                                objects: objects,
                            },
                            invoices: {
                                seq: rawInvoices.length + 1,
                                items: rawInvoices.map((item, index) => ({
                                    rowIndex: index + 1,
                                    data: {
                                        name: item.name,
                                        date: toDate(item.date),
                                        value: toDecimal(item.value),
                                        status: item.status,
                                        comment: item.comment,
                                    },
                                }))
                            }
                        }
                    }
                } else {
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR,
                    }
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })

    }

    getPtoReportData(id: number): Promise<ApiResponse<PtoReport>> {
        return this.client
            .get(`/v1/pto/${id}/report`, {baseURL: this.config.baseUrl})
            .then((response: AxiosResponse): ApiResponse<PtoReport> => {
                if (response.status === 200) {
                    return {
                        data: {
                            documentName: response.data.document_name,
                            overviewBlocks: response.data.overview_blocks,
                            summaryTablesByObject: response.data.summary_tables_by_object,
                            invoice: {
                                invoices: (response.data.invoice?.invoices as Array<Record<string, any>> || []).map(item => ({
                                    name: item.name,
                                    date: toDate(item.date),
                                    value: item.value,
                                    status: item.status,
                                    comment: item.comment,
                                })),
                                total: response.data.invoice?.total || "0₽",
                                moreThanContractAmount: response.data.invoice?.moreThanContractAmount || false,
                            },
                            kcOverview: {
                                kcs: (response.data.kc_overview?.kcs as Array<Record<string, any>> || []).map(item => ({
                                    name: item.name,
                                    signFlag: item.sign_flag,
                                    value: item.value,
                                    issues: item.issues,
                                })),
                                total: response.data.kc_overview?.total || "0₽",
                            }
                        }
                    }
                } else {
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR,
                    }
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    // kc region
    listKc(): Promise<ApiResponse<Kc[]>> {
        return this.entityList("kc", this.config, (item: Record<string, any>) => {
            return {
                id: item.id,
                name: item.name,
                path: formatFilePath(item.path),

                pto_document_id: item.pto_document_id,
                pto_contract_number: item.pto_contract_number,
                pto_contract_date: item.pto_contract_date,

                update_date: moment.utc(item.update_date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                sign_flag: item.sign_flag,
                doc_state: item.doc_state,

                warnings: item.warnings,
                items_number: item.items_number,
                objects: item.objects,

                total_material_price: toDecimal(item.total_material_price),
                total_work_price: toDecimal(item.total_work_price),
                total_price: toDecimal(item.total_price),

                items:  {isLoaded: true, data: []},
                issues: {isLoaded: true, data: []},
            }
        })
    }

    getKc(id: string): Promise<ApiResponse<Kc>> {
        return this.client
            .get(`/v1/kc/${id}`, {baseURL: this.config.baseUrl})
            .then((response: AxiosResponse): ApiResponse<Kc> => {
                if (response.status === 200) {
                    const data = response.data

                    const issues: DocumentIssue[] = (data.issues as Array<Record<string, any>> || []).map((item) => ({
                        id: item.id,
                        document_type: item.document_type,
                        document_id: item.document_id,
                        issue_type: item.issue_type,
                        data: item.data,
                    }));

                    const items: KcItem[] = (data.items as Array<Record<string, any>> || []).map((item) => ({
                        index: item.Index,
                        pto_item_index: item.PtoItemIndex,
                        object: item.Object,
                        name: item.Name,
                        units: item.Units,
                        quantity: toDecimal(item.Quantity),

                        material_price: toDecimal(item.MaterialPrice),
                        work_price: toDecimal(item.WorkPrice),
                        total_material_price: toDecimal(item.TotalMaterialPrice),
                        total_work_price: toDecimal(item.TotalWorkPrice),

                        issue_types: {},
                    }));

                    items.forEach(item => {
                        item.issue_types = this.selectKcItemIssue(item, issues)
                    })

                    return {
                        data: {
                            id: data.id,
                            name: data.name,
                            path: formatFilePath(data.path),

                            pto_document_id: data.pto_document_id,
                            pto_contract_number: data.pto_contract_number,
                            pto_contract_date: data.pto_contract_date,

                            update_date: moment.utc(data.update_date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                            sign_flag: data.sign_flag,
                            doc_state: data.doc_state,

                            warnings: data.warnings,
                            items_number: data.items_number,
                            objects: data.objects,

                            total_material_price: toDecimal(data.total_material_price),
                            total_work_price: toDecimal(data.total_work_price),
                            total_price: toDecimal(data.total_price),
                            ...this.selectKcExpectedTotalPrice(issues),

                            items: {
                                isLoaded: false,
                                fetchTime: moment(),
                                data: items,
                            },
                            issues: {
                                isLoaded: false,
                                fetchTime: moment(),
                                data: issues,
                            }
                        }
                    }
                } else {
                    return {
                        errorReason: ErrorReason.UNKNOWN_ERROR,
                    }
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    selectKcItemIssue(item: KcItem, issues: DocumentIssue[]): {[x: string]: boolean} {
        let result = {}
        issues.forEach(issue => {
            switch (issue.issue_type) {
                case IssueType.KC2_UNKNOWN_ITEM:
                case IssueType.KC2_ANOTHER_ITEM_NAME:
                case IssueType.KC2_ANOTHER_ITEM_UNIT:
                case IssueType.KC2_ANOTHER_ITEM_QUANTITY:
                case IssueType.KC2_ANOTHER_ITEM_MATERIAL_PRICE:
                case IssueType.KC2_ANOTHER_ITEM_WORK_PRICE:
                case IssueType.KC2_WRONG_ITEM_TOTAL_MATERIAL_PRICE:
                case IssueType.KC2_WRONG_ITEM_TOTAL_WORK_PRICE:
                    if (issue.data.document_object === item.object && issue.data.document_item_index === item.pto_item_index.toString()) {
                        result = Object.assign(result, {[issue.issue_type]: true})
                    }
                    break
            }
        })
        return result
    }

    selectKcExpectedTotalPrice(issues: DocumentIssue[]): Pick<Kc, "expected_total_material_price" | "expected_total_work_price" | "expected_total_price"> {
        let result: Pick<Kc, "expected_total_material_price" | "expected_total_work_price" | "expected_total_price"> = {}
        for (let issue of issues) {
            switch (issue.issue_type) {
                case IssueType.KC2_WRONG_TOTAL_PRICE:
                    result.expected_total_price = issue.data["expected"] || ""
                    break
                case IssueType.KC2_WRONG_TOTAL_MATERIAL_PRICE:
                    result.expected_total_material_price = issue.data["expected"] || ""
                    break
                case IssueType.KC2_WRONG_TOTAL_WORK_PRICE:
                    result.expected_total_work_price = issue.data["expected"] || ""
                    break
            }
        }
        return result
    }

    signKc(id: string, value: boolean): Promise<ApiResponse<string>> {
        return this.client
            .get(`/v1/kc/${id}/${value ? "sign": "unsign"}`, {baseURL: this.config.baseUrl})
            .then((response: AxiosResponse): ApiResponse<string> => {
                if (response.status === 200) {
                    return {
                        data: "ok",
                    }
                }
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    deleteKc(id: string): Promise<ApiResponse<string>> {
        return this.client
            .delete(`/v1/kc/${id}`, {baseURL: this.config.baseUrl})
            .then((response: AxiosResponse): ApiResponse<string> => {
                if (response.status === 200) {
                    return {
                        data: "ok",
                    }
                }
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }

    syncKc(period: SyncPeriod): Promise<ApiResponse<string>> {
        return this.client
            .get("/v1/kc/sync", {
                baseURL: this.config.baseUrl,
                params: {
                    period: period || "3hour",
                }
            })
            .then((response: AxiosResponse): ApiResponse<string> => {
                if (response.status === 200) {
                    return {
                        data: "ok",
                    }
                }
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }
}

function toDecimal(value: number): Decimal {
    try {
        const penny = value % 100;
        let pennyStr = penny.toString(10)
        if (penny < 10) {
            pennyStr = "0" + pennyStr
        }

        return new Decimal(`${Math.trunc(value / 100)}.${pennyStr}`);
    } catch (e) {
        console.log(e)
        return new Decimal(0)
    }
}

function toDate(value: string): moment.Moment | undefined {
    try {
        if (value.length === 10) {
            return moment.utc(value, 'DD.MM.YYYY');
        }
        if (value.length === 8) {
            return moment.utc(value, 'DD.MM.YY');
        }
        return moment.utc(value, 'YYYY-MM-DDThh:mm:ss.SSSZ');
    } catch (e) {
        console.log(e)
    }
    return undefined
}

function parsePto(data: Record<string, any>): Omit<Pto, 'items' | 'invoices'> {
    return {
        id: data.id,
        contract_number: data.contract_number,
        contract_date: moment.utc(data.contract_date, 'DD.MM.YYYY'),

        customer: data.customer,
        contractor: data.contractor,
        settlement: data.settlement,

        create_date: moment.utc(data.create_date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
        update_date: moment.utc(data.update_date, 'YYYY-MM-DDThh:mm:ss.SSSZ'),

        prepayment: toDecimal(data.prepayment),
        pto_total: toDecimal(data.pto_total),
        kc_total: toDecimal(data.kc_total),
        invoice_total: toDecimal(data.invoice_total),
        total_warnings: data.total_warnings,
        kc_refs: data.kc_refs,
    }
}

function formatFilePath(path: string): string {
    return path.replaceAll("/", " / ").trim()
}

export const apiClient = new ApiClient(
    {
        baseUrl: process.env.REACT_APP_APP_MODULE_API_BASE_URL || "http://localhost:8080",
    },
    axios.create(),
)

export { ApiClient, toDecimal }
export type { SyncPeriod }