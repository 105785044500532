import React from "react";
import {connect, useDispatch} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";

import {Button} from "@mui/material";

import {Menu, MenuItem, MenuNavigationContainer} from "../../qdep/components/menu/Menu";
import {Workspace} from "../../qdep/components/workspace/Workspace";
import {PtoListWorkspaceBlock} from "../../workspace/pto/list/ui/PtoListWorkspaceBlock";
import {PtoWizardWorkspace} from "../../workspace/pto/editor/ui/PtoWizardWorkspace";
import {PtoEditorWorkspace} from "../../workspace/pto/editor/ui/PtoEditorWorkspace";
import {PtoReportWorkspace} from "../../workspace/pto/report/ui/PtoReportWorkspace";
import {KcListWorkspaceBlock} from "../../workspace/kc/list/ui/KcListWorkspaceBlock";
import {Main} from "../../qdep/components/main/Main";
import {AppState} from "../state/state";
import {Spacer} from "../../qdep/components/util/spacer/Spacer";

import './MainPage.css'
import {logout} from "../../workspace/auth/login-block/data";


interface MainPageProps {
    isLogged: boolean

    appVersion: string
}

const MainPage = (props: MainPageProps) => {
    const dispatch = useDispatch();

    if (!props.isLogged) {
        return <Redirect to="/login"/>
    }

    return <Main>
        <Menu>
            <MenuNavigationContainer>
                <MenuItem title={"Реестр Договоров"} path={"/pto"}/>
                <MenuItem title={"Реестр КС-2"} path={"/kc"}/>
                <li className={"menu-leaf-item"} style={{color: "rgba(0, 0, 0, 0.5)"}}>
                    Задачи
                </li>

                <li className={"menu-leaf-item"} style={{fontWeight: 500}}>
                    Справочники
                </li>
                <ul style={{padding: "0 0.6rem"}}>
                    <li className={"menu-leaf-item"} style={{color: "rgba(0, 0, 0, 0.5)"}}>
                        Наименование работ
                    </li>
                    <li className={"menu-leaf-item"} style={{color: "rgba(0, 0, 0, 0.5)"}}>
                        Единицы измерения
                    </li>
                </ul>
            </MenuNavigationContainer>
            <Spacer/>
            <div className="main-menu-action-container">
                <Button
                    variant="outlined"
                    onClick={() => dispatch(logout())}
                >
                    Выйти
                </Button>
                <span className={"platform-desc"}>{ props.appVersion }</span>
            </div>
        </Menu>

        <Workspace>
            <Switch>
                <Route exact path="/">
                    <Redirect to={"/pto"}/>
                </Route>

                <Route exact path={"/pto"}>
                    <PtoListWorkspaceBlock/>
                </Route>
                <Route exact path={"/pto/create"}>
                    <PtoWizardWorkspace/>
                </Route>
                <Route exact path={"/pto/:id/edit"}>
                    <PtoEditorWorkspace/>
                </Route>
                <Route path={"/pto/:id/report"}>
                    <PtoReportWorkspace/>
                </Route>

                <Route exact path={"/kc"}>
                    <KcListWorkspaceBlock/>
                </Route>
            </Switch>
        </Workspace>
    </Main>
}

const mapStateToProps = (state: AppState): Omit<MainPageProps, "appVersion"> => {
    return {
        isLogged: state.auth.isLogged,
    };
}

const ConnectedMainPage = connect(mapStateToProps)(MainPage)

export {ConnectedMainPage as MainPage}