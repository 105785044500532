import {KcInfo} from "../../../../app/client/app/entity/pto_report";
import {Avatar, Chip, Stack, Typography} from "@mui/material";
import React from "react";
import {muiStandardTheme} from "../../../../qdep/components/theme/mui";
import {DefaultObjectName} from "../../../../app/client/app/entity/pto";


const SignedChipAvatarStyle = {color: muiStandardTheme.palette.success.main, backgroundColor: muiStandardTheme.palette.success.contrastText}
const NotSignedChipAvatarStyle = {color: muiStandardTheme.palette.error.main, backgroundColor: muiStandardTheme.palette.error.contrastText}

interface SummaryTableHeaderProps {
    objectName: string
    kcInfo: KcInfo[]
}

const SummaryTableHeader = (props: SummaryTableHeaderProps) => {
    return <div className={"summary-table-header-container"}>
        { props.objectName !== DefaultObjectName &&
            <Typography variant={"h6"}>{ props.objectName }</Typography>
        }
        { props.kcInfo.length > 0 &&
            <Stack direction="row" spacing={1} className={"document-chips-container"}>
                <div>КС-2:</div>
                { props.kcInfo.map((item, index) =>
                    <Chip
                        key={`kc-chip=${index}`}
                        avatar={item.issues
                            ? <Avatar style={ item.sign_flag ? SignedChipAvatarStyle : NotSignedChipAvatarStyle }>{item.issues}</Avatar>
                            : undefined
                        }
                        label={item.name}
                        color={item.sign_flag ? "success" : "error"}
                    />
                )}
            </Stack>
        }
    </div>
}

export {SummaryTableHeader}