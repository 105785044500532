import React from "react";

import {Stack} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import {Kc} from "../../../../../../app/client/app/entity/kc";
import {Label} from "../../../../../../qdep/components/util/label/Label";
import {
    formatDate,
    kcDocumentPtoContractNameFormatter,
    kcDocumentStateFormatter,
    MoneyFormat
} from "../../../../../../app/ui/formatter";
import {DefaultObjectName} from "../../../../../../app/client/app/entity/pto";


interface PriceWarningProps {
    value: string
}

const PriceWarning = (props: PriceWarningProps) => {
    return <Stack direction={"row"} alignItems={"center"} spacing={1} style={{color: "rgb(229, 44, 91)"}}>
        <WarningIcon color={"error"}/>
        <span>Ожидается:</span>
        <MoneyFormat value={props.value}/>
    </Stack>
}

interface KcDocumentPreviewOverviewProps {
    entity: Kc,
}

const KcOverview = (props: KcDocumentPreviewOverviewProps) => {
    return <div className={"kc-document-preview-container"}>

        <div className={"filed-container"}>
            <label>ID</label>
            <span className={"field-control"}>
                {props.entity.id}
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Наименование файла</label>
            <span className={"field-control"}>
                {props.entity.name}
            </span>
        </div>
        <div className={"filed-container"}>
            <label>Путь к файлу</label>
            <span className={"field-control"}>
                {props.entity.path}
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Договор ID</label>
            <span className={"field-control"}>
                { props.entity.pto_document_id
                    ? props.entity.pto_document_id
                    : <Label variant={"error"} text={"Нет связанного договора"}/>
                }
            </span>
        </div>
        <div className={"filed-container"}>
            <label>Договор</label>
            <span className={"field-control"}>
                { kcDocumentPtoContractNameFormatter(props.entity) }
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Статус</label>
            <span style={{minWidth: "200px"}}>
                { props.entity.sign_flag
                    ? <Label variant={"success"} text="Подписано"/>
                    : <Label variant={"error"} text="Не подписано"/>
                }
            </span>
        </div>
        <div className={"filed-container"}>
            <label>Статус Документа</label>
            <span className={"field-control"}>
                { kcDocumentStateFormatter(props.entity.doc_state) }
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Дата Обновления</label>
            <span className={"field-control"}>
                { formatDate(props.entity.update_date) }
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Объекты</label>
            <span className={"field-control"}>
                { props.entity.objects && props.entity.objects.length > 0
                    ? props.entity.objects.filter((object: string) => object !== DefaultObjectName).join(", ")
                    : "-"
                }
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Ошибки</label>
            <span className={"field-control"}>
                {props.entity.warnings}
            </span>
        </div>
        <div className={"filed-container"}>
            <label>Количество записей</label>
            <span className={"field-control"}>
                {props.entity.items_number}
            </span>
        </div>

        <div className={"filed-container"}>
            <label>Стоимость материалов</label>
            <Stack direction={"row"} alignItems={"center"} spacing={2} height={36} className={"field-control"}>
                <span style={{minWidth: "120px"}}><MoneyFormat value={props.entity.total_material_price.toString()}/></span>
                { props.entity.expected_total_material_price && <PriceWarning value={props.entity.expected_total_material_price}/> }
            </Stack>
        </div>
        <div className={"filed-container"}>
            <label>Стоимость работ</label>
            <Stack direction={"row"} alignItems={"center"} spacing={2} height={36} className={"field-control"}>
                <span style={{minWidth: "120px"}}><MoneyFormat value={props.entity.total_work_price.toString()}/></span>
                { props.entity.expected_total_work_price && <PriceWarning value={props.entity.expected_total_work_price}/> }
            </Stack>
        </div>
        <div className={"filed-container"}>
            <label>ИТОГО</label>
            <Stack direction={"row"} alignItems={"center"} spacing={2} height={36} className={"field-control"}>
                <span style={{minWidth: "120px"}}><MoneyFormat value={props.entity.total_price.toString()}/></span>
                { props.entity.expected_total_price && <PriceWarning value={props.entity.expected_total_price}/> }
            </Stack>
        </div>

    </div>
}

export {KcOverview}
