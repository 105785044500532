import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import {Label} from "../../qdep/components/util/label/Label";
import {Kc} from "../client/app/entity/kc";

interface MoneyFormatProps {
    value: string
}

const MoneyFormat = React.forwardRef<NumberFormat, MoneyFormatProps>(
    function NumberFormatCustom(props, ref) {
        const { value, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                thousandSeparator=" "
                decimalSeparator="."
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                suffix="₽"
                displayType="text"
                value={value}
            />
        );
    },
)

interface MoneyFormatControllerProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const MoneyFormatController = React.forwardRef<NumberFormat, MoneyFormatControllerProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator=" "
                decimalSeparator="."
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                suffix="₽"
            />
        );
    },
)

interface NumberFormatControllerProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const DecimalFormatController = React.forwardRef<NumberFormat, NumberFormatControllerProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator=" "
                decimalSeparator="."
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
            />
        );
    },
)

interface DecimalFormatProps {
    value: string
}

const DecimalFormat = React.forwardRef<NumberFormat, DecimalFormatProps>(
    function NumberFormatCustom(props, ref) {
        const { value, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                thousandSeparator=" "
                decimalSeparator="."
                isNumericString
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="text"
                value={value}
            />
        );
    },
)

function formatDate(value: moment.Moment): string {
    return value.format('DD.MM.YYYY HH:mm')
}

function kcDocumentStateFormatter(state: string): string | JSX.Element {
    switch (state) {
        case "PARSING_FAILED":  return <Label text={"Неверный\u00A0формат"} variant={"error"}/>
        case "PARSING_SUCCESS": return <Label text={"Успешно\u00A0разобран"} variant={"success"}/>
        case "ANALYSING": return <Label text={"Анализируется"} variant={"info"}/>
        case "ANALYSED":  return <Label text={"Проверен"} variant={"success"}/>
        default: return state
    }
}

function kcDocumentPtoContractNameFormatter(value: Kc): string | JSX.Element {
    if (value.pto_contract_number === null || value.pto_contract_date === null) {
        return <Label variant={"error"} text={`Неизвестно`}/>;
    }
    return `${value.pto_contract_number} от ${value.pto_contract_date}`
}

export {
    MoneyFormat, MoneyFormatController,
    DecimalFormat, DecimalFormatController,
    formatDate,
    kcDocumentStateFormatter, kcDocumentPtoContractNameFormatter,
}
