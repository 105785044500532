import React from "react";
import {Dispatch} from "redux";
import {connect, useSelector} from "react-redux";
import { TransitionGroup } from 'react-transition-group';

import {Collapse} from "@mui/material";

import {
    EntityListBlock,
    EntityListBlockAction,
    EntityListBlockProps
} from "../../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {AppState} from "../../../../app/state/state";
import {EntityListSettings} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {defaultKcListState} from "../data/state";
import {listRequest, listSelectRow, updateSettings} from "../data/action";
import {
    formatDate,
    kcDocumentPtoContractNameFormatter,
    kcDocumentStateFormatter,
    MoneyFormat
} from "../../../../app/ui/formatter";
import {formatBoolean} from "../../../../qdep/components/entity-list-workspace-block/formatters";
import {DataSourceBlock} from "./source/DataSourceBlock";
import {DefaultRowFactory} from "../../../../qdep/components/entity-list-workspace-block/default/RowFactory";
import {KcDocumentView} from "./view/KcDocumentView";
import {DefaultObjectName} from "../../../../app/client/app/entity/pto";


const mapStateToProps = (state: AppState): EntityListBlockProps => {
    const data = state.kc?.data || [];
    return {
        title: "КС-2",
        titleDetail: data.data !== undefined ? `(${data.data.length})`: "",
        tableCss: "kc_list_table",

        settings: state.kc?.settings || defaultKcListState.settings,
        data: data,
        selectedRow: state.kc?.selectedRow,
        isLoading: state.kc.isLoading,

        columnSpec: [
            {name: "id", title: "ID"},
            {name: "name", title: "Файл"},
            {name: "update_date", title: "Последнее обновление", format: formatDate},
            {name: "pto_document_id", title: "Договор ID"},
            {
                name: "pto_contract_number",
                title: "Договор",
                calc: kcDocumentPtoContractNameFormatter,
            },
            {name: "sign_flag", title: "Подписан", format: formatBoolean},
            {name: "doc_state", title: "Статус Документа", format: kcDocumentStateFormatter},
            {name: "warnings", title: "Ошибки"},
            {name: "items_number", title: "Количество Элементов"},
            {name: "objects", title: "Объекты",
                format: value => !value ? "-": value.filter((object: string) => object !== DefaultObjectName).join(", ")
            },
            {name: "total_price", title: "ИТОГО", format: value => <MoneyFormat value={value.toString()}/>},
        ],
        rowFactory: DefaultRowFactory,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): EntityListBlockAction => {
    return {
        refresh: (limit: number) => dispatch(listRequest(limit)),
        updateSettings: (settings: EntityListSettings) => dispatch(updateSettings(settings)),
        select: (index: number) => dispatch(listSelectRow(index))
    }
}

const KcListBlock = connect(mapStateToProps, mapDispatchToProps)(EntityListBlock)

const selectedEntitySelector = (state: AppState) => state.kc.selectedEntity

const KcListWorkspaceBlock = () => {
    const selectedEntity = useSelector(selectedEntitySelector);

    return <>
        <DataSourceBlock/>
        <div
            className={"workspace-block"}
            style={{
                maxHeight: "500px",
                overflowY: "scroll",
            }}
        >
            <KcListBlock/>
        </div>
        <TransitionGroup>
            { selectedEntity !== undefined &&
                <Collapse in={true}>
                    <div className={"workspace-block"}>
                        <KcDocumentView entity={selectedEntity}/>
                    </div>
                </Collapse>
            }
        </TransitionGroup>
    </>;
}

export {KcListWorkspaceBlock}