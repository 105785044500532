import React from "react";
import {AnyAction} from "redux";

import {Objects} from "../../../../../../app/client/app/entity/pto";
import {PtoItemListWorkspaceBlock} from "./PtoItemListWorkspaceBlock";
import {AddObjectButton} from "./AddObjectButton";
import {Button, Stack} from "@mui/material";
import {addDefaultObjectAction} from "../../../data/action";

interface PtoObjectsWorkspaceBlockProps {
    objects: Objects
    dispatch: React.Dispatch<AnyAction>
}

const PtoObjectsWorkspaceBlock = (props: PtoObjectsWorkspaceBlockProps) => {
    return <>
        { Object.keys(props.objects).length === 0
            ? <EmptyObjectList dispatch={props.dispatch}/>
            : <ObjectList
                objects={props.objects}
                dispatch={props.dispatch}
            />
        }
    </>
}

const EmptyObjectList = (props: {dispatch: React.Dispatch<any>}) => {
    return <div className={"workspace-block"}>
        <div className={"empty_pto_item_container"}>
            <div>
                <img src={"/empty_list.svg"} alt={""} width="168" height="107"/>
            </div>
            <div className={"title"}>
                Еще нет ни одного объекта
            </div>

            <Stack gap={"8px"}>
                <AddObjectButton
                    title={"Создать с объектом"}
                    variant={"contained"}
                    dispatch={props.dispatch}
                />
                <Button
                    variant={"outlined"}
                    onClick={() => props.dispatch(addDefaultObjectAction())}
                >
                    Создать без объекта
                </Button>
            </Stack>
        </div>
    </div>
}

interface ObjectListProps {
    objects: Objects

    dispatch: React.Dispatch<any>
}

const ObjectList = (props: ObjectListProps) => {
    return <>
        { Object.keys(props.objects).map((object) => {
            return <PtoItemListWorkspaceBlock
                key={`object-table-${object}`}
                object={object}
                objectData={props.objects[object]}
                dispatch={props.dispatch}
            />
        })}
    </>;
}

export {PtoObjectsWorkspaceBlock}