import React from "react";

import {PtoReport} from "../../../../app/client/app/entity/pto_report";
import {SummaryTable} from "./SummaryTable";
import {PtoReportOverview} from "./PtoReportOverview";


interface PtoReportBodyProps {
    data: PtoReport
}

const PtoReportBody = (props: PtoReportBodyProps) => {
    return <>
        <PtoReportOverview
            blocks={props.data.overviewBlocks}
            invoiceOverview={props.data.invoice}
            kcOverview={props.data.kcOverview}
        />
        { props.data.summaryTablesByObject
            .map((data) => <SummaryTable data={data}/>)
        }
    </>
}

export {PtoReportBody}