import {EntityListSettingsState} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {Kc} from "../../../../app/client/app/entity/kc";
import {DataPiece} from "../../../../app/client/app/entity/util";

interface KcListState extends EntityListSettingsState{
    data: DataPiece<Kc[]>

    selectedRow?: number
    selectedEntity?: Kc

    isLoading?: boolean
    syncOpDisabled?: boolean
}

const defaultKcListState: KcListState = {
    data: {isLoaded: false, data: []},
    settings: {
        pageSize: 25,
        columns: {
            "ID": false,
            "Файл": true,
            "Последнее обновление": true,
            "Договор ID": false,
            "Договор": true,
            "Подписан": true,
            "Статус Документа": true,
            "Ошибки": true,
            "Количество Элементов": false,
            "Объекты": false,
            "ИТОГО": false,
        }
    }
}

export type {KcListState}
export {defaultKcListState}