import React, {useState} from "react";
import {AnyAction} from "redux";

import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import {defaultInvoice, InvoiceEditorDialog} from "./InvoiceEditorDialog";
import {Invoice} from "../../../../../../app/client/app/entity/pto";
import {IndexedTableItem} from "../../../../../../app/client/app/entity/util";
import {updateInvoice} from "../../../data/invoice_reducer";


interface EditInvoiceButtonProps {
    disabled?: boolean
    invoice: IndexedTableItem<Invoice> | undefined
    dispatch: React.Dispatch<AnyAction>
}

const EditInvoiceButton = (props: EditInvoiceButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);
    return <>
        <IconButton
            disabled={props.disabled}
            onClick={() => setShowDialog(true)}
        >
            <EditIcon/>
        </IconButton>
        <InvoiceEditorDialog
            isOpen={showDialog}
            close={() => setShowDialog(false)}

            invoice={props.invoice?.data || defaultInvoice}
            apply={invoice => props.dispatch(updateInvoice(props.invoice?.rowIndex, invoice))}
        />
    </>
}

export {EditInvoiceButton}