import {ApiClientProps, ApiResponse, ErrorReason} from "../types";
import axios, {AxiosInstance, AxiosResponse} from "axios";


interface User {
    userId: number
    permissions: string[]

    firstName: string
    lastName: string
    roleName: string
}

interface AuthRequestData {
    username: string
    password: string
    project: string
}

interface AuthResponseData {
    token: string
    userInfo: User
    expiredTime: number
}

interface AuthClientProps extends ApiClientProps {
    projectUuid: string
}

class AuthClient {
    readonly config: AuthClientProps
    readonly client: AxiosInstance

    constructor(props: AuthClientProps, client: AxiosInstance) {
        this.config = props
        this.client = client

        this.login = this.login.bind(this)
    }

    login(data: AuthRequestData): Promise<ApiResponse<AuthResponseData>> {
        return this.client
            .post(
                '/auth/v1/login',
                { ...data, project: this.config.projectUuid },
                {
                    baseURL: this.config.baseUrl,
                    timeout: 5000,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    validateStatus: () => true
                },
            )
            .then((response: AxiosResponse): ApiResponse<AuthResponseData> => {
                switch (response.status) {
                    case 200: {
                        const token = response.headers.token
                        const payload = JSON.parse(window.atob(token.split('.')[1]))

                        const tokenExpirationTime = payload.exp
                        const userPermissions = payload.per
                        const userId = payload.usr

                        return {
                            data: {
                                token: token,
                                expiredTime: tokenExpirationTime,
                                userInfo: {
                                    userId: userId,
                                    permissions: userPermissions,

                                    firstName: response.data.first_name,
                                    lastName: response.data.last_name,
                                    roleName: response.data.role_name
                                }
                            }
                        }
                    }
                    case 401: {
                        return {
                            errorReason: ErrorReason.WRONG_USER_OR_PASS
                        }
                    }
                    default: {
                        return {
                            errorReason: ErrorReason.UNKNOWN_ERROR
                        }
                    }
                }
            })
    }
}

export const authClient = new AuthClient(
    {
        baseUrl: process.env.REACT_APP_AUTH_MODULE_API_BASE_URL || "http://localhost:8081",
        projectUuid: "d7023f41-070a-45e4-9654-811852f58ef0",
    },
    axios.create(),
)

export { AuthClient }
export type { AuthRequestData, AuthResponseData, User }