import {AnyAction} from "redux";
import {
    updateDataReducer,
    updateSettingsReducer
} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {KcListActionTypes} from "./action";
import {defaultKcListState, KcListState} from "./state";
import {Kc} from "../../../../app/client/app/entity/kc";

function updateEntityReducer(state: KcListState, action: AnyAction): KcListState {
    const document: Kc = action.entity;

    const documentIndex = state.data.data.findIndex((item: Kc) => item.id === document.id);
    if (documentIndex !== -1) {
        const newData = [...state.data.data];
        newData[documentIndex] = document

        let kc: Kc | undefined = state.selectedEntity;
        if (kc && kc.id === document.id) {
            kc = newData[documentIndex]
        }

        return {
            ...state,
            data: {
                ...state.data,
                data: newData,
            },
            selectedEntity: kc,
        }
    }

    return state
}

function selectRowReducer(state: KcListState, action: AnyAction): KcListState {
    let index = action.index
    let oldIndex = state.selectedRow;
    if (oldIndex === index) {
        index = undefined
    }

    let kc: Kc | undefined = undefined;
    if (state.data && state.data.data.length > index) {
        kc = state.data.data[index]
    }

    return {
        ...state,
        selectedEntity: kc,
        selectedRow: index
    }
}

function signReducer(state: KcListState, action: AnyAction): KcListState {
    const documentIndex = state.data.data.findIndex((item: Kc) => item.id === action.id);
    if (documentIndex !== -1) {
        const newData = [...state.data.data];
        newData[documentIndex] = {
            ...newData[documentIndex],
            sign_flag: action.value,
        }

        let kc: Kc | undefined = state.selectedEntity;
        if (kc && kc.id === action.id) {
            kc = newData[documentIndex]
        }

        return {
            ...state,
            data: {
                ...state.data,
                data: newData,
            },
            selectedEntity: kc,
        }
    }

    return state
}

function deleteEntityReducer(state: KcListState, action: AnyAction): KcListState {
    const documentIndex = state.data.data.findIndex((item: Kc) => item.id === action.id);
    if (documentIndex > -1) {
        const newData = [...state.data.data];
        newData.splice(documentIndex, 1)
        return {
            ...state,
            data: {
                ...state.data,
                data: newData,
            }
        }
    }
    return state
}

function kcListReducer(state: KcListState = defaultKcListState, action: AnyAction): KcListState {
    switch (action.type) {
        case KcListActionTypes.START_LOADING:
            return {...state, isLoading: true}
        case KcListActionTypes.LIST_REQUEST_FAILED:
            return {...state, isLoading: false}
        case KcListActionTypes.UPDATE_SETTINGS:
            return updateSettingsReducer(state, action)
        case KcListActionTypes.SELECT_ROW:
            return selectRowReducer(state, action)
        case KcListActionTypes.SYNC_OP_DISABLED:
            return {...state, syncOpDisabled: action.value}
        case KcListActionTypes.LIST_REQUEST_SUCCEEDED:
            return updateDataReducer(state, action)
        case KcListActionTypes.GET_ENTITY_REQUEST_SUCCEEDED:
            return updateEntityReducer(state, action)
        case KcListActionTypes.DELETE_ENTITY_REQUEST_SUCCEEDED:
            return deleteEntityReducer(state, action)
        case KcListActionTypes.SIGN_ENTITY_REQUEST_SUCCEEDED:
            return signReducer(state, action)
    }
    return state
}

export {kcListReducer}
