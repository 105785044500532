import React, {useState} from "react";
import {AnyAction} from "redux";

import {
    Button,
    ButtonGroup, ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grow, ListItemIcon, MenuItem, MenuList, Paper,
    Popper,
    TextField
} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsVariantOverrides} from "@mui/material/Button/Button";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import TableRowsIcon from '@mui/icons-material/TableRows';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {addDefaultObjectAction, addObjectAction} from "../../../data/action";

interface AddObjectDialogProps {
    isOpen: boolean
    close: () => void

    createObject: (name: string) => void
}

const AddObjectDialog = (props: AddObjectDialogProps) => {
    const [objectName, setObjectName] = useState("");

    return <Dialog open={props.isOpen}>
        <DialogTitle>Создать объект</DialogTitle>
        <DialogContent style={{paddingTop: "2px"}}>
            <div className={"filed-container small"}>
                <label>Наименование</label>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    value={objectName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setObjectName(event.target.value)
                    }}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Отменить</Button>
            <Button variant="contained" color="secondary"
                    onClick={() => {
                        props.createObject(objectName)
                        props.close()
                    }}
            >
                Создать
            </Button>
        </DialogActions>
    </Dialog>
}

interface AddObjectButtonProps {
    variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
    title?: string

    dispatch: React.Dispatch<AnyAction>
}

const AddObjectButton = (props: AddObjectButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);

    return <>
        <Button
            variant={ props.variant === undefined ? "outlined" : props.variant }
            onClick={() => setShowDialog(true)}
        >
            { props.title === undefined ? "Добавить объект" : props.title }
        </Button>
        <AddObjectDialog
            isOpen={showDialog}
            close={() => setShowDialog(false)}
            createObject={(name: string) => props.dispatch(addObjectAction(name))}
        />
    </>
}

interface GroupAddObjectButtonProps extends AddObjectButtonProps {
    disableCreateDefaultObject: boolean
}

const GroupAddObjectButton = (props: GroupAddObjectButtonProps) => {
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const [showDialog, setShowDialog] = useState(false);

    return <>
        <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
            <Button
                variant={ props.variant === undefined ? "outlined" : props.variant }
                onClick={() => setShowDialog(true)}
            >
                { props.title === undefined ? "Добавить объект" : props.title }
            </Button>
            <Button
                size="small"
                style={{padding: "3px"}}
                onClick={() => setOpen(true)}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{zIndex: 10}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <MenuList id="split-button-menu">
                                <MenuItem
                                    disabled={props.disableCreateDefaultObject}
                                    onClick={() => {
                                        props.dispatch(addDefaultObjectAction())
                                        setOpen(false)
                                    }}
                                >
                                    <ListItemIcon>
                                        <TableRowsIcon/>
                                    </ListItemIcon>
                                    Создать без объекта
                                </MenuItem>
                                <MenuItem disabled>
                                    <ListItemIcon>
                                        <UploadFileIcon/>
                                    </ListItemIcon>
                                    Импорт
                                </MenuItem>
                                <MenuItem disabled>
                                    <ListItemIcon>
                                        <BackupTableIcon/>
                                    </ListItemIcon>
                                    Создать из шаблона
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
        <AddObjectDialog
            isOpen={showDialog}
            close={() => setShowDialog(false)}
            createObject={(name: string) => props.dispatch(addObjectAction(name))}
        />
    </>
}

export {AddObjectButton, GroupAddObjectButton}