import {ApiResponse, ErrorReason} from "../../../app/client/types";
import {authClient, AuthRequestData, AuthResponseData} from "../../../app/client/auth/client";
import {AnyAction} from "redux";
import {AuthState} from "../../../app/state/state";
import {call, put, takeEvery} from "redux-saga/effects";

enum LoginActions {
    LOGIN_REQUEST           = "LOGIN_REQUEST",
    LOGIN_REQUEST_SUCCEEDED = 'LOGIN_REQUEST.SUCCEEDED',
    LOGIN_REQUEST_FAILED    = 'LOGIN_REQUEST.FAILED',

    REFRESH_REQUEST           = "REFRESH_REQUEST",
    REFRESH_REQUEST_SUCCEEDED = 'REFRESH_REQUEST.SUCCEEDED',
    REFRESH_REQUEST_FAILED    = 'REFRESH_REQUEST.FAILED',

    AUTH_TOKEN_EXPIRED = "AUTH_TOKEN_EXPIRED",

    LOGOUT = 'LOGOUT',
}

function loginRequest(username: string, password: string): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST,
        username: username,
        password: password
    }
}

function loginRequestSucceeded(data: AuthResponseData): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST_SUCCEEDED,
        token: data.token,
        expirationTime: data.expiredTime,
        user: data.userInfo,
    }
}

function loginRequestFailed(errReason: ErrorReason): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST_FAILED,
        errReason: errReason
    }
}

function authTokenExpired(): AnyAction {
    return {
        type: LoginActions.AUTH_TOKEN_EXPIRED,
    }
}

function logout(): AnyAction {
    return {
        type: LoginActions.LOGOUT
    }
}

const initState = {
    isLogged: false,
    isWaiting: false,
    errorMessage: undefined,

    token: '',
    expiredTime: -1,
    userInfo: {
        userId: -1,
        permissions: [],
        firstName: 'undefined',
        lastName: 'undefined',
        roleName: 'undefined'
    }
}

function authReducer(state: AuthState = initState, action: AnyAction): AuthState {
    switch (action.type) {
        case LoginActions.LOGIN_REQUEST:
            return {
                ...state,
                isWaiting: true,
            }
        case LoginActions.LOGIN_REQUEST_SUCCEEDED:
            return {
                isLogged: true,
                isWaiting: false,
                errorMessage: undefined,

                token: action.token,
                expiredTime: action.expirationTime,
                userInfo: action.user,
            }
        case LoginActions.LOGIN_REQUEST_FAILED:
            let errMessage: string
            switch (action.errReason) {
                case ErrorReason.WRONG_USER_OR_PASS:
                    errMessage = 'Неверное имя пользователя или пароль'
                    break
                default:
                    errMessage = 'Неизвестная ошибка. Попробуйте позже или обратитесь к администратору'
                    break
            }
            return {
                ...state,
                isLogged: false,
                isWaiting: false,
                errorMessage: errMessage
            }
        case LoginActions.AUTH_TOKEN_EXPIRED:
            return {
                ...initState,
                isLogged: false,
                isWaiting: false,
                errorMessage: 'Сессия истекла, пожалуйста, войдите в систему снова'
            }
        case LoginActions.LOGOUT:
            return initState
    }

    return state;
}

function* loginRequestSaga(action: AnyAction) {
    const requestData: AuthRequestData = {
        username: action.username,
        password: action.password,
        project: "",
    }
    const response: ApiResponse<AuthResponseData> = yield call(authClient.login, requestData)
    if (!response.errorReason && response.data) {
        yield put(loginRequestSucceeded(response.data))
    } else {
        const err: ErrorReason = response.errorReason || ErrorReason.UNKNOWN_ERROR
        yield put(loginRequestFailed(err))
    }
}

function* watchAuthModuleSaga() {
    yield takeEvery(LoginActions.LOGIN_REQUEST, loginRequestSaga)
}

export {
    LoginActions,
    authReducer,
    loginRequest, loginRequestSucceeded, loginRequestFailed,
    authTokenExpired, logout,
    watchAuthModuleSaga,
}