import {AnyAction} from "redux";

import {PtoEditorActionTypes} from "./action";
import {NewPto, Pto} from "../../../../app/client/app/entity/pto";


type PtoEditorPage = "working" | "loading" | "not_found" | "unknown_error" | "get_entity_error"

interface PtoEditorState {
    page: PtoEditorPage
    entityId: number | undefined
    entity: Pto
}

const defaultPtoEditorState: PtoEditorState = {
    page: "loading",
    entityId: undefined,
    entity: NewPto(),
}

function ptoEditorReducer(state: PtoEditorState = defaultPtoEditorState, action: AnyAction): PtoEditorState {
    switch (action.type) {
        case PtoEditorActionTypes.EDITOR_LOADER:
            return {
                ...state,
                page: action.value,
            }
        case PtoEditorActionTypes.EDITOR_CLEAR:
            return defaultPtoEditorState
        case PtoEditorActionTypes.EDITOR_UPDATE_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    ...action.data,
                }
            }
        case PtoEditorActionTypes.GET_ENTITY_REQUEST:
            return {
                ...state,
                entityId: action.entityId,
            }
        case PtoEditorActionTypes.GET_ENTITY_REQUEST_SUCCEEDED:
            return {
                ...state,
                entity: action.entity,
            }
    }
    return state
}

export type {PtoEditorPage, PtoEditorState}
export {ptoEditorReducer}
