import {MouseEventHandler} from "react";
import {ColumnSpec, defaultColumnSpecKey, QTableAction, QTableStaticProps, RowFactory} from "@qdep/react-table";

function defaultCellFormatter(data: any): string {
    return data ? data : '-'
}

function selectRowCallback(handler: (value: any) => void): MouseEventHandler<HTMLTableRowElement> {
    return (event: React.MouseEvent<HTMLTableRowElement>) => {
        event.preventDefault();
        const row = event.currentTarget
        handler(row.rowIndex - 1)
    }
}

const DefaultRowFactory: RowFactory = (rowData: any, props: QTableStaticProps & QTableAction, rowIndex?: number) => {
    let rowClass = ""
    if (props.selectedRow !== undefined && props.selectedRow === rowIndex) {
        rowClass = "selected"
    }
    return <tr className={rowClass} key={rowIndex} onClick={props.selectRow ? selectRowCallback(props.selectRow): undefined}>
        { props.spec.map((column: ColumnSpec) => {
            const value = rowData[column.name];
            const cellValue = column.format ? column.format(value) : defaultCellFormatter(value)
            return <td key={defaultColumnSpecKey(column)}>{cellValue}</td>;
        })}
    </tr>
}

export {DefaultRowFactory}
