import React from "react";
import {Button, ClickAwayListener, Grow, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ActionGroupButtonProps {
    menu: React.ReactElement
}

// todo https://mui.com/components/menus/#customization
const ActionGroupButton = (props: ActionGroupButtonProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    return <>
        <Button variant="outlined"
                className="entity-table-control-panel-btn-group"
                sx={{
                    padding: "0 5px 0 12px",
                }}
                ref={anchorRef}
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
            Действия <ArrowDropDownIcon />
        </Button>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{zIndex: 10}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            {props.menu}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>
}

export {ActionGroupButton}
export type {ActionGroupButtonProps}