import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

import {ListItemIcon, MenuItem} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import {AppState} from "../../../../../app/state/state";


interface EditActionButtonProps {
    ptoId: number | undefined
}

const EditActionButton = (props: EditActionButtonProps) => {
    const history = useHistory();

    return <MenuItem
        key="edit"
        disabled={props.ptoId === undefined}
        onClick={() => {
            if (props.ptoId !== undefined) {
                history.push(`/pto/${props.ptoId}/edit`)
            }
        }}
    >
        <ListItemIcon>
            <EditIcon/>
        </ListItemIcon>
        Редактировать
    </MenuItem>
}

const mapStateToProps = (state: AppState): EditActionButtonProps => {
    let ptoId: number | undefined
    if (state.pto.selectedRow !== undefined && state.pto.selectedRow >= 0) {
        ptoId = state.pto.data.data[state.pto.selectedRow].id
    }
    return {
        ptoId: ptoId
    };
}

const ConnectedEditActionButton = connect(mapStateToProps)(EditActionButton)

export {ConnectedEditActionButton as EditActionButton}
