import {AnyAction} from "redux";
import {DataPiece} from "../../../app/client/app/entity/util";
import moment from "moment/moment";

interface EntityListDataState<DataType> {
    data: DataPiece<DataType[]>
    isLoading?: boolean
}

interface EntityListSettingsState {
    settings: EntityListSettings
}

interface EntityListSettings {
    pageSize: number
    columns: EntityListColumnSettings
}

interface EntityListColumnSettings {
    [key: string]: boolean
}

function updateDataReducer<T extends EntityListDataState<any>>(state: T, action: AnyAction): T {
    return {
        ...state,
        data: {
            isLoaded: true,
            data: action.data,
            fetchTime: moment(),
        },
        isLoading: false,
    }
}

function updateSettingsReducer<T extends EntityListSettingsState>(state: T, action: AnyAction): T {
    return {
        ...state,
        settings: action.settings,
    }
}

export type {EntityListDataState, EntityListColumnSettings, EntityListSettings, EntityListSettingsState}
export {updateDataReducer, updateSettingsReducer}