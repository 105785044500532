import React from "react";

import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";

import {DocumentIssue, IssueType} from "../../../../../../app/client/app/entity/document_issue";
import {MoneyFormat} from "../../../../../../app/ui/formatter";
import {DefaultObjectName} from "../../../../../../app/client/app/entity/pto";

interface KcDocumentPreviewLogsProps {
    items: DocumentIssue[] | undefined
}

const KcDocumentPreviewLogs = (props: KcDocumentPreviewLogsProps) => {
    let children: React.ReactElement | React.ReactElement[]
    if (props.items === undefined || props.items.length === 0) {
        children = <ListItem><ListItemText primary={"Нет записей"}/></ListItem>
    } else {
        children = props.items?.map(formatDocumentIssue)
    }
    return <div className={"kc-document-preview-container"}>
        <List>
            { children }
        </List>
    </div>;
}

function formatDocumentIssue(item: DocumentIssue, index: number): React.ReactElement {
    let primary: string = item.issue_type
    let secondary: React.ReactNode | undefined = undefined
    switch (item.issue_type) {
        case IssueType.KC2_WRONG_ITEM_TOTAL_MATERIAL_PRICE:
            primary = "Некоректная стоимость материалов"
            if (item.data.item_name !== undefined && item.data.item_name) {
                primary += ` "${item.data.item_name}"`
            }
            secondary = <>
                <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
            </>
            break
        case IssueType.KC2_WRONG_ITEM_TOTAL_WORK_PRICE:
            primary = "Некоректная стоимость работ"
            if (item.data.item_name !== undefined && item.data.item_name) {
                primary += ` "${item.data.item_name}"`
            }
            secondary = <>
                <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
            </>
            break
        case IssueType.KC2_WRONG_TOTAL_PRICE:
            primary = "Некоректная итоговая стоимость"
            if (item.data.expected && item.data.actual) {
                secondary = <span>
                    Ожидаемая: <MoneyFormat value={item.data.expected}/>, Фактическая: <MoneyFormat value={item.data.actual}/>
                </span>
            }
            break
        case IssueType.KC2_ANOTHER_ITEM_NAME:
            primary = "Наименование работ не совпадает с Договором"
            if (item.data.expected && item.data.actual) {
                secondary = <>
                    <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
                    <div>Ожидается: "{item.data.expected}", Факт: "{item.data.actual}</div>
                </>
            }
            break
        case IssueType.KC2_ANOTHER_ITEM_UNIT:
            primary = "Единица измерения не совпадает с Договором"
            if (item.data.expected && item.data.actual) {
                secondary = <>
                    <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
                    <div>Ожидается: "{item.data.expected}", Факт: "{item.data.actual}"</div>
                </>
            }
            break
        case IssueType.KC2_ANOTHER_ITEM_WORK_PRICE:
            primary = "Стоимость работ не совпадает с Договором"
            if (item.data.expected && item.data.actual) {
                secondary = <>
                    <div>Объект: {item.data.document_object}, Номер работ: {item.data.document_item_index}</div>
                    <div>Ожидаемая: <MoneyFormat value={item.data.expected}/>, Фактическая: <MoneyFormat value={item.data.actual}/></div>
                </>
            }
            break
        case IssueType.KC2_ANOTHER_ITEM_QUANTITY:
            primary = "Объем работ не совпадает с Договором"
            if (item.data.expected && item.data.actual) {
                secondary = <>
                    <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
                    <div>Ожидаемая: <MoneyFormat value={item.data.expected}/>, Фактическая: <MoneyFormat value={item.data.actual}/></div>
                </>
            }
            break
        case IssueType.KC2_ANOTHER_ITEM_MATERIAL_PRICE:
            primary = "Цена материалов не совпадает с Договором"
            if (item.data.expected && item.data.actual) {
                secondary = <>
                    <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
                    <div>Ожидаемая: <MoneyFormat value={item.data.expected}/>, Фактическая: <MoneyFormat value={item.data.actual}/></div>
                </>
            }
            break
        case IssueType.KC2_UNKNOWN_ITEM:
            primary = "Элемент отсутствует в Договоре"
            secondary = <>
                <div>{renderObjectName(item.data.document_object)} Номер работ: {item.data.document_item_index}</div>
            </>
            break
        case IssueType.KC2_WRONG_TOTAL_MATERIAL_PRICE:
            primary = "Некоректная итоговая стоимость материалов"
            if (item.data.expected && item.data.actual) {
                secondary = <span>
                    Ожидаемая: <MoneyFormat value={item.data.expected}/>, Фактическая: <MoneyFormat value={item.data.actual}/>
                </span>
            }
            break
        case IssueType.KC2_WRONG_TOTAL_WORK_PRICE:
            primary = "Некоректная итоговая стоимость работ"
            if (item.data.expected && item.data.actual) {
                secondary = <span>
                    Ожидаемая: <MoneyFormat value={item.data.expected}/>, Фактическая: <MoneyFormat value={item.data.actual}/>
                </span>
            }
            break
        case IssueType.KC2_NOT_ASSIGNED_TO_PTO:
            primary = "Связанный договор не найден"
            break
    }
    return <ListItem>
        <ListItemAvatar>
            <Avatar>{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary}/>
    </ListItem>
}

function renderObjectName(name: string | undefined): string {
    if (name !== undefined && name !== DefaultObjectName) {
        return `Объект: ${name},`
    }
    return "";
}

export {KcDocumentPreviewLogs}