import React from "react";

import './Label.css'

interface LabelProps {
    text: string
    variant: "success" | "error" | "info"
}

const Label = (props: LabelProps) => {
    return <div className={"label-container " + props.variant}>
        <div className={"text"}>
            { props.text }
        </div>
    </div>
}

export {Label}
