import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../state/state";
import {SnackbarKey, useSnackbar} from "notistack";
import {useEffect} from "react";
import {notificationRemove} from "./reducer";


let displayed: SnackbarKey[] = [];

const useNotifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store: AppState) => store.notification || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(notification => {
            if (displayed.includes(notification.key)) return;
            enqueueSnackbar(notification.message, {
                key:
                notification.key,
                variant: notification.variant,
                onExited: (event, snackbarKey) => {
                    dispatch(notificationRemove(notification.key))
                    removeDisplayed(notification.key);
                },
            });

            storeDisplayed(notification.key);
        })
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

}

export default useNotifier;