import { Dispatch, MiddlewareAPI } from 'redux'
import { AppState, CheckExpiredTime } from "./state";
import { authTokenExpired } from "../../workspace/auth/login-block/data";
import {PtoEditorActionTypes} from "../../workspace/pto/editor/data/action";
import {PtoListActionTypes} from "../../workspace/pto/list/data/action";
import {KcListActionTypes} from "../../workspace/kc/list/data/action";

const authMiddleware = (store: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => (action: any) => {

    switch (action.type) {
        case PtoEditorActionTypes.CREATE_ENTITY_REQUEST:
        case PtoEditorActionTypes.UPDATE_ENTITY_REQUEST:
        case PtoListActionTypes.LIST_REQUEST:
        case KcListActionTypes.LIST_REQUEST:
        case KcListActionTypes.GET_ENTITY_REQUEST:
        case KcListActionTypes.SIGN_ENTITY_REQUEST:
            // todo periodic check
            const state = store.getState();
            const expiredTime = state.auth.expiredTime;
            if (CheckExpiredTime(expiredTime)) {
                return next(authTokenExpired())
            }
            break
    }

    return next(action)
}

export {authMiddleware}