import React from "react";

import {Spacer} from "../../../../../qdep/components/util/spacer/Spacer";
import {SyncButton} from "./SyncButton";


const DataSourceBlock = () => {

    return <div className={"workspace-block"}>
        <div className={"header"}>
            <span>Data Source</span>
            <Spacer/>
            <SyncButton/>
        </div>
        <div className={"body"}>
            <div className={"filed-container"}>
                <label>Тип Источника</label>
                <span className={"field-control"}>Google Drive</span>
            </div>
            <div className={"filed-container"}>
                <label>ID Папки</label>
                <span className={"field-control"}>1N_VzOQyDRwd4uGba6HwmTBR9NWxfeu90</span>
            </div>
            {/*<div className={"filed-container"}>*/}
            {/*    <label>Last update time</label>*/}
            {/*    <span className={"field-control"}>Temporarily unavailable</span>*/}
            {/*</div>*/}
            {/*<div className={"filed-container"}>*/}
            {/*    <label>Next Update Time</label>*/}
            {/*    <span className={"field-control"}>Temporarily unavailable</span>*/}
            {/*</div>*/}
            <div className={"filed-container"}>
                <label>Глубина Синхронизации</label>
                <span className={"field-control"}>4</span>
            </div>
        </div>
    </div>
}

export {DataSourceBlock}