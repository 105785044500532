import React, {useEffect, useState} from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import RefreshIcon from '@mui/icons-material/Refresh';
import {IconButton, Tooltip} from "@mui/material";

import {EntityListSettings} from "./EntityListSettingsState";

import './EntityTableControlPanel.css'
import {EntityListSettingsDialog} from "./EntityListSettingsDialog";
import moment from "moment/moment";

interface EntityTableControlPanelProps {
    title: string
    titleDetail?: string

    actionGroup?: React.ReactElement

    settings?: EntityListSettings

    refresh?: () => void
    refreshTime?: moment.Moment

    updateSettingsCallback: (settings: EntityListSettings) => void
}

const EntityTableControlPanel = (props: EntityTableControlPanelProps) => {
    return (
        <div className={"entity-table-control-panel-container"}>
            <div className={"entity-table-control-panel-header"}>
                <span>{props.title}</span>
                {props.titleDetail ? <span className={"entity-table-control-panel-header-counter"}>{props.titleDetail}</span> : ""}
            </div>

            <div className={"entity-table-control-panel-spanner"}/>

            {renderRefreshBtn(props.refresh, props.refreshTime)}
            {renderActionGroup(props.actionGroup)}
            {renderSettingsButton(props)}
        </div>
    )
}

function renderRefreshBtn(callback: (() => void) | undefined, refreshTime: moment.Moment | undefined) {
    if (callback !== undefined) {
        return <div className="entity-table-control-panel-btn-group">
            { refreshTime &&
                <div className={"entity-table-control-panel-refresh-label"}>
                    <RefreshTimer time={refreshTime}/>
                </div>
            }
            <Tooltip title={"Обновить таблицу"}>
                <IconButton onClick={callback}>
                    <RefreshIcon/>
                </IconButton>
            </Tooltip>
        </div>
    }
}

interface RefreshTimerProps {
    time: moment.Moment
}

const RefreshTimer = (props: RefreshTimerProps) => {
    const [diff, setDiff] = useState(0);
    useEffect(() => {
        setDiff(moment().diff(props.time, "minute"))
    }, [props.time])
    useEffect(() => {
        const interval = setInterval(() => setDiff(prev => {
            return prev + 1
        }), 60_000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return <span>Последнее обновление {diff > 0 ? diff: "меньше"} минуту назад</span>
}

function renderActionGroup(props: React.ReactElement | undefined) {
    if (props !== undefined) {
        return props
    }
}

interface SettingsButtonProps {
    settings: EntityListSettings,
    updateSettingsCallback: (settings: EntityListSettings) => void
}

const SettingsButton = (props: SettingsButtonProps) => {
    const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState(props.settings)

    return <>
        <div className="entity-table-control-panel-btn-group">
            <Tooltip title={"Настройки"}>
                <IconButton onClick={() => setShowSettings(true)}>
                    <SettingsIcon height="20px" width="20px"/>
                </IconButton>
            </Tooltip>
        </div>
        <EntityListSettingsDialog
            isOpen={showSettings}
            close={() => setShowSettings(false)}

            settings={settings}
            save={() => {
                props.updateSettingsCallback(settings)
                setShowSettings(false)
            }}

            update={(settings) => setSettings(settings)}
        />
    </>
}

function renderSettingsButton(props: EntityTableControlPanelProps) {
    if (props.settings !== undefined) {
        return <SettingsButton settings={props.settings} updateSettingsCallback={props.updateSettingsCallback}/>
    }
}


export {EntityTableControlPanel}
export type {EntityTableControlPanelProps}