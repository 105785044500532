import {AnyAction} from "redux";

import {Kc} from "../../../../app/client/app/entity/kc";
import {EntityListSettings} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {SyncPeriod} from "../../../../app/client/app/client";

export enum KcListActionTypes {
    LIST_REQUEST           = 'WORKSPACE.KC_LIST.LIST_REQUEST',
    LIST_REQUEST_SUCCEEDED = 'WORKSPACE.KC_LIST.LIST_REQUEST.SUCCEEDED',
    LIST_REQUEST_FAILED    = 'WORKSPACE.KC_LIST.LIST_REQUEST.FAILED',

    GET_ENTITY_REQUEST           = 'WORKSPACE.KC_LIST.GET_ENTITY_REQUEST',
    GET_ENTITY_REQUEST_SUCCEEDED = 'WORKSPACE.KC_LIST.GET_ENTITY_REQUEST.SUCCEEDED',
    GET_ENTITY_REQUEST_FAILED    = 'WORKSPACE.KC_LIST.GET_ENTITY_REQUEST.FAILED',

    SIGN_ENTITY_REQUEST           = 'WORKSPACE.KC_LIST.SIGN_ENTITY_REQUEST',
    SIGN_ENTITY_REQUEST_SUCCEEDED = 'WORKSPACE.KC_LIST.SIGN_ENTITY_REQUEST.SUCCEEDED',
    SIGN_ENTITY_REQUEST_FAILED    = 'WORKSPACE.KC_LIST.SIGN_ENTITY_REQUEST.FAILED',

    DELETE_ENTITY_REQUEST           = 'WORKSPACE.KC_LIST.DELETE_ENTITY_REQUEST',
    DELETE_ENTITY_REQUEST_SUCCEEDED = 'WORKSPACE.KC_LIST.DELETE_ENTITY_REQUEST.SUCCEEDED',
    DELETE_ENTITY_REQUEST_FAILED    = 'WORKSPACE.KC_LIST.DELETE_ENTITY_REQUEST.FAILED',

    SYNC_REQUEST           = 'WORKSPACE.KC_LIST.SYNC_REQUEST',
    SYNC_REQUEST_SUCCEEDED = 'WORKSPACE.KC_LIST.SYNC_REQUEST.SUCCEEDED',
    SYNC_REQUEST_FAILED    = 'WORKSPACE.KC_LIST.SYNC_REQUEST.FAILED',

    START_LOADING = 'WORKSPACE.KC_LIST.START_LOADING',
    SELECT_ROW    = 'WORKSPACE.KC_LIST.SELECT_ROW',

    UPDATE_SETTINGS = 'WORKSPACE.KC_LIST.UPDATE_SETTINGS',

    SYNC_OP_DISABLED = 'WORKSPACE.KC_LIST.SYNC_OP_DISABLED',
}

function listRequest(limit: number): AnyAction {
    return {
        type: KcListActionTypes.LIST_REQUEST,
        limit: limit,
    }
}

function listRequestSucceeded(data: Kc[]): AnyAction {
    return {
        type: KcListActionTypes.LIST_REQUEST_SUCCEEDED,
        data: data
    }
}

function listRequestFailed(): AnyAction {
    return {
        type: KcListActionTypes.LIST_REQUEST_FAILED,
    }
}

// ------------------------------------------------------------------------
function getEntityRequest(id: string): AnyAction {
    return {
        type: KcListActionTypes.GET_ENTITY_REQUEST,
        id: id,
    }
}

function getEntityRequestSucceeded(entity: Kc): AnyAction {
    return {
        type: KcListActionTypes.GET_ENTITY_REQUEST_SUCCEEDED,
        entity: entity,
    }
}

function getEntityRequestFailed(): AnyAction {
    return {
        type: KcListActionTypes.GET_ENTITY_REQUEST_FAILED,
    }
}

// ------------------------------------------------------------------------
function signEntityRequest(id: string, value: boolean): AnyAction {
    return {
        type: KcListActionTypes.SIGN_ENTITY_REQUEST,
        id: id,
        value: value,
    }
}

function signEntityRequestSucceeded(id: string, value: boolean): AnyAction {
    return {
        type: KcListActionTypes.SIGN_ENTITY_REQUEST_SUCCEEDED,
        id: id,
        value: value,
    }
}

function signEntityRequestFailed(id: string): AnyAction {
    return {
        type: KcListActionTypes.SIGN_ENTITY_REQUEST_FAILED,
        id: id,
    }
}

// ------------------------------------------------------------------------
function deleteEntityRequest(id: string): AnyAction {
    return {
        type: KcListActionTypes.DELETE_ENTITY_REQUEST,
        id: id,
    }
}

function deleteEntityRequestSucceeded(id: string): AnyAction {
    return {
        type: KcListActionTypes.DELETE_ENTITY_REQUEST_SUCCEEDED,
        id: id,
    }
}

function deleteEntityRequestFailed(): AnyAction {
    return {
        type: KcListActionTypes.DELETE_ENTITY_REQUEST_FAILED,
    }
}

// ------------------------------------------------------------------------
function syncRequest(period: SyncPeriod): AnyAction {
    return {
        type: KcListActionTypes.SYNC_REQUEST,
        period: period,
    }
}

function syncRequestSucceeded(): AnyAction {
    return {
        type: KcListActionTypes.SYNC_REQUEST_SUCCEEDED,
    }
}

function syncRequestFailed(): AnyAction {
    return {
        type: KcListActionTypes.SYNC_REQUEST_FAILED,
    }
}

// ------------------------------------------------------------------------
function setSyncOpDisabled(value: boolean): AnyAction {
    return {
        type: KcListActionTypes.SYNC_OP_DISABLED,
        value: value,
    }
}

function startLoading(): AnyAction {
    return {
        type: KcListActionTypes.START_LOADING,
    }
}

function listSelectRow(index: number): AnyAction {
    return {
        type: KcListActionTypes.SELECT_ROW,
        index: index
    }
}

function updateSettings(settings: EntityListSettings): AnyAction {
    return {
        type: KcListActionTypes.UPDATE_SETTINGS,
        settings: settings,
    }
}

export {
    listRequest, listRequestSucceeded, listRequestFailed,
    getEntityRequest, getEntityRequestSucceeded, getEntityRequestFailed,
    deleteEntityRequest, deleteEntityRequestSucceeded, deleteEntityRequestFailed,
    signEntityRequest, signEntityRequestSucceeded, signEntityRequestFailed,
    syncRequest, syncRequestSucceeded, syncRequestFailed,
    listSelectRow, updateSettings, startLoading, setSyncOpDisabled,
}