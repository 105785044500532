import {AnyAction} from "redux";
import {call, put, takeEvery} from 'redux-saga/effects'
import {goBack} from "connected-react-router";

import {apiClient} from "../../../../app/client/app/client";
import {Pto} from "../../../../app/client/app/entity/pto";
import {ApiResponse} from "../../../../app/client/types";
import {
    clearEditor,
    createEntityRequestFailed,
    createEntityRequestSucceeded, getEntityRequest,
    getEntityRequestFailed,
    getEntityRequestSucceeded,
    PtoEditorActionTypes, setEntityId,
    updateEditorStatus,
    updateEntityRequestFailed,
    updateEntityRequestSucceeded
} from "./action";
import {notificationEmit} from "../../../../app/notification/reducer";


function* editorOpenSaga(action: AnyAction) {
    yield put(updateEditorStatus("loading"))
    yield put(clearEditor())

    const entityId = action.entityId;
    if (entityId === undefined) {
        yield put(updateEditorStatus("working"))
    } else {
        yield put(setEntityId(entityId))
        yield put(getEntityRequest(entityId))
    }
}

function* editorGetEntityRequestSaga(action: AnyAction) {
    yield put(updateEditorStatus("loading"))
    const response: ApiResponse<Pto> = yield call(apiClient.getDetailedPtoById, action.entityId)

    if (!response.errorReason && response.data) {
        yield put(getEntityRequestSucceeded(response.data))
        yield put(updateEditorStatus("working"))
    } else {
        yield put(getEntityRequestFailed())
        yield put(updateEditorStatus("get_entity_error"))
    }
}

function* editorCreateRequestSaga(action: AnyAction) {
    yield put(updateEditorStatus("loading"))
    const response: ApiResponse<Pto> = yield call(apiClient.createPto, action.entity)

    if (!response.errorReason && response.data) {
        yield put(createEntityRequestSucceeded(response.data))
        yield put(updateEditorStatus("working"))
        yield put(goBack())
        yield put(notificationEmit(
            "pto-create-request-success",
            "success",
            "Договор успешно создан",
        ));
    } else {
        yield put(createEntityRequestFailed())
        yield put(notificationEmit(
            "pto-create-request-error",
            "error",
            "Не удалось создать новый договор. Попробуйте позже или свяжитесь с администратором.",
        ));
        yield put(updateEditorStatus("unknown_error"))
    }
}

function* editorUpdateRequestSaga(action: AnyAction) {
    yield put(updateEditorStatus("loading"))
    const response: ApiResponse<Pto> = yield call(apiClient.updatePto, action.entity)

    if (!response.errorReason && response.data) {
        yield put(updateEntityRequestSucceeded(response.data))
        yield put(updateEditorStatus("working"))
        yield put(goBack())
        yield put(notificationEmit(
            "pto-update-request-success",
            "success",
            "Договор успешно обновлен",
        ));
    }else {
        yield put(updateEntityRequestFailed())
        yield put(notificationEmit(
            "pto-update-request-error",
            "error",
            "Не удалось обновить договор. Попробуйте позже или свяжитесь с администратором.",
        ));
        yield put(updateEditorStatus("unknown_error"))
    }
}

export function* watchPtoCreateWorkspace() {
    yield takeEvery(PtoEditorActionTypes.EDITOR_OPEN, editorOpenSaga)
    yield takeEvery(PtoEditorActionTypes.GET_ENTITY_REQUEST, editorGetEntityRequestSaga)
    yield takeEvery(PtoEditorActionTypes.CREATE_ENTITY_REQUEST, editorCreateRequestSaga)
    yield takeEvery(PtoEditorActionTypes.UPDATE_ENTITY_REQUEST, editorUpdateRequestSaga)
}