import {AnyAction} from "redux";
import {call, delay, put, select, takeEvery} from 'redux-saga/effects'
import {ApiResponse} from "../../../../app/client/types";
import {
    deleteEntityRequestFailed,
    deleteEntityRequestSucceeded,
    getEntityRequestFailed,
    getEntityRequestSucceeded,
    KcListActionTypes,
    listRequestFailed,
    listRequestSucceeded,
    listSelectRow,
    setSyncOpDisabled, signEntityRequestFailed, signEntityRequestSucceeded,
    startLoading,
    syncRequestFailed,
    syncRequestSucceeded
} from "./action";
import {Kc} from "../../../../app/client/app/entity/kc";
import {apiClient} from "../../../../app/client/app/client";
import {notificationEmit} from "../../../../app/notification/reducer";
import {AppState} from "../../../../app/state/state";


function* listRequestSaga() {
    yield put(startLoading())
    const response: ApiResponse<Kc[]> = yield call(apiClient.listKc)
    if (!response.errorReason && response.data) {
        yield put(listRequestSucceeded(response.data))
    } else {
        yield put(listRequestFailed())
        yield put(notificationEmit(
            "kc-list-request",
            "error",
            "Не удалось загрузить данные. Попробуйте позже или свяжитесь с администратором.",
        ));
    }
}

function* getEntitySaga(action: AnyAction) {
    const response: ApiResponse<Kc> = yield call(apiClient.getKc, action.id)
    if (!response.errorReason && response.data) {
        yield put(getEntityRequestSucceeded(response.data))
    } else {
        yield put(getEntityRequestFailed())
        yield put(notificationEmit(
            "kc-document-request",
            "error",
            "Не удалось загрузить данные. Попробуйте позже или свяжитесь с администратором.",
        ));
    }
}

const selectedRowSelector = (state: AppState) => state.kc.selectedRow;

function* deleteEntitySaga(action: AnyAction) {
    const response: ApiResponse<Kc> = yield call(apiClient.deleteKc, action.id)
    if (!response.errorReason && response.data) {
        const selectedRow: number | undefined = yield select(selectedRowSelector);
        if (selectedRow !== undefined) {
            yield put(listSelectRow(selectedRow));
        }
        yield put(deleteEntityRequestSucceeded(action.id))
        yield put(notificationEmit(
            "kc-document-delete-request-success",
            "success",
            "Документ успешно удален.",
        ));
    } else {
        yield put(deleteEntityRequestFailed())
        yield put(notificationEmit(
            "kc-document-delete-request-error",
            "error",
            "Не удалось удалить документ. Попробуйте позже или свяжитесь с администратором.",
        ));
    }
}

function* syncSaga(action: AnyAction) {
    yield put(setSyncOpDisabled(true))
    const response: ApiResponse<Kc> = yield call(apiClient.syncKc, action.period)
    if (!response.errorReason && response.data) {
        yield put(syncRequestSucceeded())
        yield put(notificationEmit(
            "kc-document-sync-request-success",
            "info",
            "Запущена синхронизация документов. Это займет несколько минут",
        ));
    } else {
        yield put(syncRequestFailed())
        yield put(notificationEmit(
            "kc-document-sync-request-error",
            "error",
            "Не запустить синхронизацию. Попробуйте позже или свяжитесь с администратором.",
        ));
    }
    yield delay(10_000)
    yield put(setSyncOpDisabled(false))
}

function* signEntitySaga(action: AnyAction) {
    const entityId: string = action.id;
    const signFlag: boolean = action.value;

    const response: ApiResponse<string> = yield call(apiClient.signKc, entityId, signFlag)
    if (!response.errorReason && response.data) {
        yield put(signEntityRequestSucceeded(entityId, signFlag))
    } else {
        yield put(signEntityRequestFailed(entityId))
    }
}

export function * watchKcListWorkspace () {
    yield takeEvery(KcListActionTypes.LIST_REQUEST, listRequestSaga)
    yield takeEvery(KcListActionTypes.GET_ENTITY_REQUEST, getEntitySaga)
    yield takeEvery(KcListActionTypes.SIGN_ENTITY_REQUEST, signEntitySaga)
    yield takeEvery(KcListActionTypes.DELETE_ENTITY_REQUEST, deleteEntitySaga)
    yield takeEvery(KcListActionTypes.SYNC_REQUEST, syncSaga)
}