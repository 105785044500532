import {applyMiddleware, combineReducers, createStore} from "redux";
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import storage from "redux-persist/es/storage";
import {PersistConfig} from "redux-persist/es/types";

import {ptoListReducer} from "../../workspace/pto/list/data/reducer";
import {kcListReducer} from "../../workspace/kc/list/data/reducer";
import {watchPtoListWorkspace} from "../../workspace/pto/list/data/saga";
import {watchKcListWorkspace} from "../../workspace/kc/list/data/saga";
import {watchPtoCreateWorkspace} from "../../workspace/pto/editor/data/saga";
import {authReducer, watchAuthModuleSaga} from "../../workspace/auth/login-block/data";
import {authMiddleware} from "./authMiddleware";
import {notificationReducer} from "../notification/reducer";
import {ptoEditorReducer} from "../../workspace/pto/editor/data/editor_reducer";


export const history = createBrowserHistory()

function settingsPersistConfigFactory(prefix: string): PersistConfig<any> {
    return {
        key: `${prefix}:settings:v3`,
        storage: storage,
        whitelist: ["settings"],
    }
}

// todo rm handler for the deprecated persisted data

const reducers = combineReducers({
    router: connectRouter(history),
    // todo consider to use cookie for auth.token
    auth: persistReducer({key: 'auth:v1', storage: storage, blacklist: ["isWaiting"]}, authReducer),
    notification: notificationReducer,

    pto: persistReducer(settingsPersistConfigFactory("pto_list"), ptoListReducer),
    ptoEditor: ptoEditorReducer,
    kc:  persistReducer(settingsPersistConfigFactory("kc_list"),  kcListReducer),
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(reducers, composeWithDevTools(applyMiddleware(
    authMiddleware, routerMiddleware(history), sagaMiddleware
)))
const persistor = persistStore(store)

sagaMiddleware.run(watchPtoListWorkspace)
sagaMiddleware.run(watchPtoCreateWorkspace)
sagaMiddleware.run(watchKcListWorkspace)
sagaMiddleware.run(watchAuthModuleSaga)

export { store, persistor }