import React from "react";
import {debounce, DebouncedFunc} from "lodash";

import {TextFieldProps} from "@mui/material/TextField/TextField";
import {MenuItem as SelectMenuItem, Select, TextField} from "@mui/material";
import {SelectProps} from "@mui/material/Select/Select";

const CustomTextField = (props: TextFieldProps) => <TextField
    size="small"
    sx={{width: "100%"}}
    inputProps={{sx: {p: "4px 32px 4px 28px"}}}
    {...props}
/>

interface DebouncedTextFieldProps {
    value: string | number
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface DebouncedTextFieldState {
    value: string | number
}

class DebouncedTextField extends React.Component<DebouncedTextFieldProps, DebouncedTextFieldState> {
    private readonly emitChanges: DebouncedFunc<(event: React.ChangeEvent<HTMLInputElement>) => void>

    constructor(props: DebouncedTextFieldProps) {
        super(props);
        this.state = {
            value: props.value
        }

        this.onChange = this.onChange.bind(this)
        this.emitChangesHandler = this.emitChangesHandler.bind(this)

        this.emitChanges = debounce(this.emitChangesHandler, 300)
    }

    componentWillUnmount() {
        this.emitChanges.cancel()
    }

    render() {
        return <CustomTextField
            value={this.state.value}
            onChange={this.onChange}
        />;
    }

    emitChangesHandler(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(event)
    }

    onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({value: event.target.value})
        this.emitChanges(event)
    }
}

const UnitsSelectController = (props: SelectProps<string>) =>
    <Select
        value={props.value}
        onChange={props.onChange}

        size="small"
        sx={{width: "100%"}}
        inputProps={{sx: {p: "4px 32px 4px 28px"}}}
    >
        <SelectMenuItem value={"-"}>-</SelectMenuItem>
        <SelectMenuItem value={"шт"}>шт</SelectMenuItem>
        <SelectMenuItem value={"м2"}>м2</SelectMenuItem>
        <SelectMenuItem value={"м3"}>м3</SelectMenuItem>
        <SelectMenuItem value={"м.п."}>м.п.</SelectMenuItem>
        <SelectMenuItem value={"комплект"}>комплект</SelectMenuItem>
    </Select>

export {DebouncedTextField, CustomTextField, UnitsSelectController}