import {AnyAction} from "redux";
import {Pto} from "../../../../app/client/app/entity/pto";
import {EntityListSettings} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";

export enum PtoListActionTypes {
    LIST_REQUEST           = 'WORKSPACE.PTO_LIST.LIST_REQUEST',
    LIST_REQUEST_SUCCEEDED = 'WORKSPACE.PTO_LIST.LIST_REQUEST.SUCCEEDED',
    LIST_REQUEST_FAILED    = 'WORKSPACE.PTO_LIST.LIST_REQUEST.FAILED',

    START_LOADING = 'WORKSPACE.PTO_LIST.START_LOADING',
    SELECT_ROW    = 'WORKSPACE.PTO_LIST.SELECT_ROW',

    UPDATE_SETTINGS = 'WORKSPACE.PTO_LIST.UPDATE_SETTINGS'
}

function listRequest(limit: number): AnyAction {
    return {
        type: PtoListActionTypes.LIST_REQUEST,
        limit: limit,
    }
}

function listRequestSucceeded(data: Pto[]): AnyAction {
    return {
        type: PtoListActionTypes.LIST_REQUEST_SUCCEEDED,
        data: data
    }
}

function listRequestFailed(): AnyAction {
    return {
        type: PtoListActionTypes.LIST_REQUEST_FAILED,
    }
}

function startLoading(): AnyAction {
    return {
        type: PtoListActionTypes.START_LOADING,
    }
}

function listSelectRow(index: number): AnyAction {
    return {
        type: PtoListActionTypes.SELECT_ROW,
        index: index
    }
}

function updateSettings(settings: EntityListSettings): AnyAction {
    return {
        type: PtoListActionTypes.UPDATE_SETTINGS,
        settings: settings,
    }
}

export {
    listRequest, listRequestSucceeded, listRequestFailed,
    listSelectRow,
    updateSettings, startLoading,
}