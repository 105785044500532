import React from "react";
import {debounce, DebouncedFunc} from "lodash";

import {SelectChangeEvent} from "@mui/material";

import {DecimalFormatController, MoneyFormat, MoneyFormatController} from "../../../../../../app/ui/formatter";
import {PtoItem} from "../../../../../../app/client/app/entity/pto";
import {CustomTextField, DebouncedTextField, UnitsSelectController} from "./controller";
import Decimal from "decimal.js-light";


interface PtoItemEditorProps {
    entity: PtoItem
    updateItemCallback: (value: PtoItemEditorState) => void
}

interface PtoItemEditorState {
    index: string
    name: string
    units: string
    quantity: string
    materialPrice: string
    workPrice: string

    totalMaterialPrice: Decimal
    totalWorkPrice: Decimal
}

class PtoItemEditor extends React.Component<PtoItemEditorProps, PtoItemEditorState> {
    private readonly emitUpdate: DebouncedFunc<(entity: PtoItemEditorState) => void>

    constructor(props: PtoItemEditorProps) {
        super(props);

        this.state = {
            index: props.entity.index.toString(10),
            name: props.entity.name,
            units: props.entity.units,
            quantity: props.entity.quantity.toString(),
            materialPrice: props.entity.materialPrice.toString(),
            workPrice: props.entity.workPrice.toString(),

            totalMaterialPrice: props.entity.quantity.mul(props.entity.materialPrice),
            totalWorkPrice: props.entity.quantity.mul(props.entity.workPrice),
        }

        this.onChange = this.onChange.bind(this)
        this.emitChangesHandler = this.emitChangesHandler.bind(this)
        this.emitUpdate = debounce((entity: PtoItemEditorState) => this.props.updateItemCallback(entity), 500)
    }

    componentWillUnmount() {
        this.emitUpdate.cancel()
    }

    onChange(update: Partial<PtoItemEditorState>) {
        this.setState(prev => {
            let newState = {...prev, ...update}

            try {
                const quantity = new Decimal(newState.quantity);
                newState.totalMaterialPrice = quantity.mul(newState.materialPrice)
                newState.totalWorkPrice = quantity.mul(newState.workPrice)
            } catch {
                newState.totalMaterialPrice = new Decimal(0)
                newState.totalWorkPrice = new Decimal(0)
            }

            return newState
        }, this.emitChangesHandler)
    }

    emitChangesHandler() {
        this.emitUpdate(this.state)
    }

    render() {
        return <>
            <td key={"index-editor"} className="item-editor-cell">
                <CustomTextField
                    value={this.state.index}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let result: Partial<PtoItemEditorState> = {}

                        const strings = event.target.value
                            .split("\t").slice(0, 6);
                        switch (strings.length) {
                            // todo fix
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 6: result.workPrice = strings[5].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 5: result.materialPrice = strings[4].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 4: result.quantity = strings[3].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 3: result.units = strings[2].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 2: result.name = strings[1].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 1: result.index = strings[0].trim()
                        }
                        this.onChange(result)
                    }}
                />
            </td>
            <td key={"index-name"} className="item-editor-cell">
                <DebouncedTextField
                    value={this.state.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let result: Partial<PtoItemEditorState> = {}

                        const strings = event.target.value
                            .split("\t").slice(0, 5);
                        switch (strings.length) {
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 5: result.workPrice = strings[4].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 4: result.materialPrice = strings[3].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 3: result.quantity = strings[2].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 2: result.units = strings[1].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 1: result.name = strings[0].trim()
                        }
                        this.onChange(result)
                    }}
                />
            </td>
            <td key={"index-units"} className="item-editor-cell">
                <UnitsSelectController
                    value={this.state.units}
                    onChange={(event: SelectChangeEvent) => {
                        this.onChange({units: event.target.value})
                    }}
                />
            </td>
            <td key={"index-quantity"} className="item-editor-cell">
                <CustomTextField
                    InputProps={{inputComponent: DecimalFormatController as any}}
                    value={this.state.quantity}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let result: Partial<PtoItemEditorState> = {}

                        const strings = (event.target.value || "0")
                            .split("\t").slice(0, 3);
                        switch (strings.length) {
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 3: result.workPrice = strings[2].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 2: result.materialPrice = strings[1].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 1: result.quantity = strings[0].trim()
                        }
                        this.onChange(result)
                    }}
                />
            </td>
            <td key={"index-material-price"} className="item-editor-cell">
                <CustomTextField
                    InputProps={{inputComponent: MoneyFormatController as any}}
                    value={this.state.materialPrice}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let result: Partial<PtoItemEditorState> = {}

                        const strings = (event.target.value || "0")
                            .split("\t").slice(0, 2);
                        switch (strings.length) {
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 2: result.workPrice = strings[1].trim()
                            // @ts-ignore
                            // eslint-disable-next-line no-fallthrough
                            case 1: result.materialPrice = strings[0].trim()
                        }
                        this.onChange(result)
                    }}
                />
            </td>
            <td key={"index-work-price"} className="item-editor-cell">
                <CustomTextField
                    InputProps={{inputComponent: MoneyFormatController as any}}
                    value={this.state.workPrice}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let result: Partial<PtoItemEditorState> = {}

                        const strings = (event.target.value || "0")
                            .split("\t").slice(0, 1);
                        result.workPrice = strings[0].trim()

                        this.onChange(result)
                    }}
                />
            </td>
            <td key={"index-total-material-price"}>
                <MoneyFormat value={this.state.totalMaterialPrice.toString()}/>
            </td>
            <td key={"index-total-work-price"}>
                <MoneyFormat value={this.state.totalWorkPrice.toString()}/>
            </td>
        </>;
    }
}

export {PtoItemEditor}
export type {PtoItemEditorState}