import moment from "moment";

interface DataPiece <T> {
    isLoaded: boolean
    data: T
    fetchTime?: moment.Moment
}

function getDataPieceData(data: DataPiece<any>): any {
    return data.isLoaded ? (data.data ? data.data : []): []
}

interface IndexedTableItem<T> {
    rowIndex: number
    data: T
}

interface IndexedTableData<T> {
    seq: number
    items:  IndexedTableItem<T>[]
}

export {
    getDataPieceData,
}

export type {
    DataPiece,
    IndexedTableItem, IndexedTableData,
}