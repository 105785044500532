import { createTheme } from '@mui/material/styles';

const muiStandardTheme = createTheme({
    palette: {
        primary: {
            main: '#687078',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ec7211',
        },
        error: {
            main: 'rgb(229, 44, 91)',
            contrastText: '#fff',
        },
        success: {
            main: 'rgb(47, 175, 100)',
            contrastText: '#fff',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: '#687078',
                },
            }
        }
    }
});

export {muiStandardTheme}
